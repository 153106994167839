import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import FbPixel from "../utils/fbPixel";
import { Helmet } from "react-helmet";
import { ChevronLeft } from "lucide-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
export default function Terms() {
  useEffect(() => {
    mixpanel.track("Navigate to Terms Page");
    FbPixel("Navigate to Terms Page");
  }, []);
  return (
    <>
      <Header />
      <div className="text-white content  flex flex-col p-4 gap-4 max-w-6xl m-auto">
        <Helmet>
          <title>
            Zingroll Terms & Conditions – User Guidelines & Policies
          </title>
          <meta
            name="description"
            content="Read the Terms & Conditions of Zingroll to understand our policies on AI-generated content, user rights, and platform usage. Stay informed and compliant."
          />
        </Helmet>
        <div>
          <span className="p-2 bg-zinc-800 rounded-full inline-block">
            <ChevronLeft
              onClick={() => window.history.back()}
              className="cursor-pointer"
            />
          </span>
        </div>
        <div className="flex items-center gap-2">
          <img className="size-8" src="/logo2.png" />
          <h1 className="text-xl font-bold">Zingroll Terms and Conditions</h1>
        </div>

        <p>
          Welcome to <b>Zingroll</b>, a platform owned by{" "}
          <b>Harshit Foundation</b>. These Terms and Conditions ("Agreement")
          govern your use of the Zingroll application, website, and related
          services (collectively, the "Services"). By accessing or using the
          Services, you agree to comply with these Terms and Conditions. If you
          do not agree to these Terms, do not use the Services.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By using the Services, you acknowledge and agree to these Terms and
          Conditions and any applicable rules, guidelines, or policies that may
          be posted on the platform. If you do not agree to these Terms, you
          must discontinue your use of the Services.
        </p>

        <h2>2. User Responsibilities</h2>
        <p>
          You are solely responsible for all User Content (e.g., videos, text,
          images, audio) that you create, upload, remix, or share through
          Zingroll. You agree to the following:
        </p>
        <ul>
          <li>
            <b>Ownership of User Conten</b>: You retain all rights, title, and
            interest in your User Content, subject to the rights you grant to
            Zingroll under this Agreement. By submitting User Content, you grant
            Zingroll a non-exclusive, royalty-free, worldwide, transferable
            license to use, modify, distribute, display, and perform your User
            Content solely to operate and improve the Services.
          </li>
          <li>
            <b>Compliance with Laws</b>: You are solely responsible for ensuring
            that your User Content does not infringe on any third-party
            intellectual property rights, including but not limited to
            copyrights, trademarks, or patents. You must comply with all
            applicable local, national, and international laws, including
            copyright laws.
          </li>
          <li>
            <b>AI-Generated Content</b>: You acknowledge that content created
            through Zingroll may involve AI-generated materials or may utilize
            publicly available creative commons content. You are responsible for
            ensuring that any AI-generated content you produce complies with all
            applicable laws and does not violate any third-party rights.
          </li>
          <li>
            <b>Data Responsibility</b>: You are responsible for the data you
            upload and share. You acknowledge that Zingroll does not control or
            monitor User Content and is not liable for any data loss, damage, or
            misuse of your content.
          </li>
        </ul>
        <h2>3. Restrictions on Use</h2>
        <p>You agree not to:</p>
        <ul>
          <li>
            Use the Services to create or distribute content that is unlawful,
            obscene, offensive, defamatory, harmful, or otherwise objectionable.
          </li>
          <li>
            Violate any third-party intellectual property rights, including but
            not limited to copyright infringement, or breach any confidentiality
            agreements or privacy laws.
          </li>
          <li>
            Reverse engineer, decompile, disassemble, or attempt to derive the
            source code of the Zingroll platform.
          </li>
          <li>
            Use the platform to distribute viruses, malware, or any other
            harmful code.
          </li>
        </ul>
        <h2>4. Warranties and Disclaimers</h2>
        <ul>
          <li>
            <b>No Warranty</b>: The Services are provided "as is" and "as
            available," without any warranty of any kind, express or implied,
            including, but not limited to, the warranties of merchantability,
            fitness for a particular purpose, or non-infringement. Zingroll
            makes no representations or warranties regarding the accuracy,
            completeness, or reliability of any content, including AI-generated
            content, provided through the platform.
          </li>
          <li>
            <b>Third-Party Content</b>: Zingroll does not guarantee that any
            content, whether AI-generated or otherwise, is free from errors,
            copyright issues, or inappropriate material. Zingroll is not
            responsible for any third-party content or for the actions of third
            parties that may arise from your use of the platform.
          </li>
          <li>
            <b>No Liability for Loss or Damage</b>: Zingroll will not be held
            liable for any loss or damage (including indirect, consequential, or
            punitive damages) that may occur from your use of the platform or
            from any third-party interactions resulting from the content you
            produce or share.
          </li>
        </ul>

        <h2>5. Indemnification</h2>
        <p>
          You agree to indemnify, defend, and hold harmless Zingroll, Harshit
          Foundation, its affiliates, employees, agents, and licensors from and
          against any claims, liabilities, damages, costs, or expenses
          (including reasonable attorneys' fees) arising from:
        </p>
        <ul>
          <li>Your violation of these Terms and Conditions.</li>
          <li>
            Your User Content, including any infringement of third-party rights.
          </li>
          <li>
            Your use or misuse of the Services, including AI-generated content.
          </li>
        </ul>

        <h2>6. Limitation of Liability</h2>
        <ul>
          <li>
            <b>Limitation of Damages</b>: To the maximum extent permitted by
            law, Zingroll and Harshit Foundation will not be liable for any
            direct, indirect, incidental, special, consequential, or punitive
            damages, including but not limited to loss of profits, data, or
            other intangible losses arising from your use or inability to use
            the Services, or from any AI-generated content or third-party
            interactions.
          </li>
          <li>
            <b>Maximum Liability</b>: In the event Zingroll is found liable for
            any reason, Zingroll’s total liability to you will be limited to the
            amount paid by you to Zingroll (if any) for the use of the Services
            in the six (6) months preceding the event giving rise to the claim.
          </li>
        </ul>

        <h2>7. Intellectual Property Rights</h2>
        <ul>
          <li>
            <b>Zingroll Ownership</b>: All intellectual property rights in the
            Services, including the platform’s software, algorithms, trademarks,
            logos, and content, are owned by Zingroll or its licensors. Nothing
            in these Terms grants you any rights to the intellectual property of
            Zingroll except for the limited license to use the platform as set
            forth in this Agreement.
          </li>
          <li>
            <b>User Content License</b>: By submitting or creating User Content,
            you grant Zingroll a non-exclusive, worldwide, royalty-free license
            to use, modify, display, distribute, and create derivative works of
            your content in connection with the operation, improvement, and
            promotion of the Services.
          </li>
        </ul>
        <h2>8. Dispute Resolution and Governing Law</h2>
        <ul>
          <li>
            <b>Arbitration Agreement</b>: Any dispute, controversy, or claim
            arising out of or relating to this Agreement shall be resolved by
            binding arbitration under the rules of the American Arbitration
            Association (AAA). The arbitration shall take place in USA, and the
            decision of the arbitrator shall be final and binding.
          </li>
          <li>
            <b>Governing Law</b>: This Agreement shall be governed by and
            construed in accordance with the laws of USA, without regard to its
            conflict of law principles.
          </li>
          <li>
            <b>Class Action Waiver</b>: You agree that any dispute or claim
            arising out of or relating to these Terms and Conditions will be
            resolved on an individual basis, and you waive any right to
            participate in a class action, class arbitration, or any other
            representative proceeding.
          </li>
        </ul>

        <h2>9. Termination</h2>
        <p>
          Zingroll reserves the right to suspend or terminate your access to the
          Services at any time, without prior notice, if you breach any of these
          Terms. You may terminate your use of the Services by discontinuing use
          of the platform.
        </p>
        <p>
          Upon termination, your right to access or use the Services will
          immediately cease, and you must promptly destroy any copies of the
          platform or related materials in your possession.
        </p>

        <h2>10. Privacy and Data Collection</h2>
        <p>
          Your use of the Services is also governed by Zingroll’s Privacy
          Policy, which can be found at <a href="/#/privacy">Privacy Policy</a>.
          By using the Services, you consent to the collection, use, and sharing
          of your data as described in the Privacy Policy.
        </p>

        <h2>11. Modification of Terms</h2>
        <p>
          Zingroll reserves the right to modify these Terms and Conditions at
          any time, without prior notice, by posting the updated Terms on the
          platform. Your continued use of the Services after such modifications
          constitutes your acceptance of the revised Terms.
        </p>

        <h2>12. Miscellaneous</h2>
        <ul>
          <li>
            <b>Entire Agreement</b>: These Terms and Conditions, together with
            any other legal notices and agreements published by Zingroll on the
            platform, constitute the entire agreement between you and Zingroll.
          </li>
          <li>
            <b>Severability</b>: If any provision of these Terms is held to be
            invalid or unenforceable, the remaining provisions will remain in
            full force and effect.
          </li>
          <li>
            <b>No Waiver</b>: The failure of Zingroll to exercise or enforce any
            right or provision of these Terms will not operate as a waiver of
            such right or provision.
          </li>
        </ul>
        <h2>13. Contact Information</h2>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact us at:
        </p>
        <ul>
          <li>
            <b>Email</b>:{" "}
            <a href="mailto:harshit@zingroll.com">harshit@zingroll.com</a>
          </li>
          <li>
            <b>Website</b>:{" "}
            <a href="https://www.zingroll.com">www.zingroll.com</a>
          </li>
        </ul>

        <p>
          By using Zingroll, you acknowledge that you have read, understood, and
          agree to these Terms and Conditions.
        </p>
      </div>
      <Footer />
    </>
  );
}
