import { api } from "../utils/api";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMovie } from "../redux/slice/Movie";
import { openModal } from "../redux/slice/LoginModal";
const t2 = {
  podcastCategory: "trending",
  hostName: "",
  createdDate: "2025-01-24T17:31:59.282Z",
  hostEmail: "",
  status: 1,
  groupID: "6a988552-6d2e-454e-86f9-e72917172b71",
  views: "",
  url: "",
  createdBy: "default-value",
  podcastUrl: "test prompt",
  imageUrl:
    "https://static-content-for-text.s3.us-east-1.amazonaws.com/image4upload/1737736015730-1736185319852-Screenshot2025-01-06at11.11.48p.jpeg",
  userID: "123",
  trailer: {
    name: "Behind the Scenes with AI",
    description:
      "In the ancient kingdom of Hastinapur, a family’s quest for power sparks a bitter rivalry that leads to a catastrophic war. As the battle for the throne intensifies, alliances are tested, and the fate of kingdoms rests on the choices of its greatest warriors, shaped by duty, destiny, and divine intervention.",
    videoUrl:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/HLS-vidoes/mahabharat-t-1/master.m3u8",
    imageUrl:
      "https://static-content-for-text.s3.us-east-1.amazonaws.com/image4upload/1737736015730-1736185319852-Screenshot2025-01-06at11.11.48p.jpeg",
  },
  category: "",
  description:
    "In the ancient kingdom of Hastinapur, a family’s quest for power sparks a bitter rivalry that leads to a catastrophic war. As the battle for the throne intensifies, alliances are tested, and the fate of kingdoms rests on the choices of its greatest warriors, shaped by duty, destiny, and divine intervention.",
  id: "180556f3-c1a1-42fe-b6c9-240a0c019930",
  duration: "",
  tags: [],
  title: "Mahabharat : An Eternal War",
  hostProfile: "",
};
export default function Episodes() {
  const dispatch = useDispatch();
  const { group, episodes, movie } = useSelector((state) => state.movie);
  const { user } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  useEffect(() => {
    window.scrollTo({ top: 80, behavior: "smooth" });
  }, [movie]);

  useEffect(() => {
    if (group?.title == "Mahabharat : An Eternal War") {
      dispatch(setMovie(t2.trailer));
    } else {
      dispatch(setMovie(group.trailer));
    }
    if (user == null) {
      setShow(true);
    } else {
      getVote();
    }
  }, []);
  const getVote = async () => {
    try {
      const res = await api.get(
        `https://36iras96ql.execute-api.us-east-2.amazonaws.com/vote?userId=${user.id}&videoId=${group.groupID}`
      );
      if (res?.data?.data == null) {
        setShow(true);
      }
    } catch (err) {
      console.log("error in geting voting", err);
    }
  };
  const handleVote = async () => {
    if (user == null) {
      dispatch(openModal());
      return;
    }
    try {
      setShow(false);
      const res = await api.post(
        "https://36iras96ql.execute-api.us-east-2.amazonaws.com/vote",
        {
          userId: user.id,
          videoId: group.groupID,
          name: user.userName || user.emailID,
        }
      );
      if (res?.data?.status == 200) {
        toast.success("Vote successfully 🎉!");
        api.post("/templategroup/update-template-group-data", {
          id: group.groupID,
          groupData: {
            trailerProgressCount: group?.trailerProgressCount + 1 || 1,
          },
        });
        api.post("/userpodcast/update-podcast-data", {
          id: group.id,
          userPodcastsData: {
            trailerProgressCount: group?.trailerProgressCount + 1 || 1,
          },
        });
      } else {
        toast.error("something went wrong");
      }
    } catch (err) {
      console.log("error in voting", err);
    }
  };

  const Episode = ({ data }) => {
    // const duration =
    //   data.videoDuration < 60
    //     ? Math.floor(data.videoDuration) + "s"
    //     : Math.floor(data.videoDuration / 60) + "m";
    return (
      <div
        className="flex items-center max-w-full rounded-lg overflow-hidden p-2 hover:bg-[#ffffff1a] cursor-pointer flex-row sm:gap-4 gap-2 sm:m-4 mb-2 sm:mb-0"
        onClick={() => dispatch(setMovie(data))}
      >
        {/* <span className="text-white text-xl hidden sm:block">
          {data.episodNumber}
        </span> */}
        <div className="relative">
          <img
            src={data.imageUrl}
            alt={data.name}
            className={`w-40 h-20 object-cover rounded-xl sm:w-60 sm:h-28 `}
          />
          {/* {movie.id === data.id && (
            <PlayCircle
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
              size={40}
            />
          )} */}
        </div>
        <div className="px-2 w-full sm:px-4">
          <div className="flex items-center justify-between">
            <h3 className="font-bold text-lg sm:text-xl text-white-800">
              {data.name}
            </h3>
            {/* <h3 className="font-bold text-xl text-white-800">
              {isNaN(duration) ? "" : duration}
            </h3> */}
          </div>
          <p className="text-gray-300 text-sm mt-1">{data.description}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="mx-auto px-4 py-8 w-full max-w-7xl md:px-12">
      {/* {group?.trailerProgressBar && (
        <div className="mb-10">
          <div className="flex items-center gap-2 justify-center">
            <div className="flex items-center h-2 bg-gray-300 w-full md:w-[80%] overflow-hidden rounded-full">
              <div
                className="h-full bg-gradient-to-r from-[#001aff] to-[#06b7f8] rounded-full animate-gradient"
                style={{
                  width: `${
                    (group.trailerProgressCount * group.trailerProgressNumber) /
                    100
                  }%`,
                }}
              ></div>
            </div>
            <span className="text-gray-400 text-sm">
              {(group.trailerProgressCount * group.trailerProgressNumber) / 100}
              %
            </span>
          </div>
          <button
            onClick={show ? handleVote : null}
            className="bg-gradient-to-r from-[#001affae] to-[#06b7f8] py-2 mt-5 rounded-lg w-40 block mx-auto animate-gradient"
          >
            <span className="">{show ? "Vote" : "Voted"}</span>
          </button>
        </div>
      )} */}
      <h2 className="text-2xl font-semibold text-white mb-4">Trailer</h2>
      {group?.title == "Mahabharat : An Eternal War" && (
        <Episode key={2} data={t2.trailer} />
      )}
      <Episode key={1} data={group.trailer} />
    </div>
  );
}
