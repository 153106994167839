import mixpanel from "mixpanel-browser";
import { useEffect } from "react";
import FbPixel from "../utils/fbPixel";
import { Helmet } from "react-helmet";
import { ChevronLeft } from "lucide-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css"; // Don't forget to import the CSS for AOS

export default function Terms() {
  useEffect(() => {
    mixpanel.track("Navigate to Zingroll Productiono Tips Page");
    FbPixel("Navigate to Terms Page");
    AOS.init({ duration: 1000 }); // Initialize AOS with custom duration if needed
  }, []);
  return (
    <>
      <Header />
      <div className="text-white flex flex-col p-4 gap-4 max-w-7xl m-auto mt-5">
        <Helmet>
          <title>Zingroll AI Video Workflow</title>
          <meta
            name="description"
            content="Explore the AI-powered video creation workflow used by Zingroll, from scripting to final cinematic production."
          />
        </Helmet>

        <div>
          <span className="p-2 bg-zinc-800 rounded-full inline-block">
            <ChevronLeft
              onClick={() => window.history.back()}
              className="cursor-pointer"
            />
          </span>
        </div>

        {/* Header */}
        <div className="flex items-center gap-2 m-auto " data-aos="fade-up">
          <img className="size-10" src="/logo2.png" alt="Zingroll Logo" />
          <h1 className="text-4xl font-extrabold text-center">
            Zingroll Production Tips
          </h1>
        </div>
        <section className="mt-7 mb-4" data-aos="fade-up">
          <div className="text-2xl mb-4 font-semibold">Tools We Use</div>
          <ul className="list-disc pl-6 space-y-1 [&>li]:text-[16px] lg:[&>li]:text-[18px]">
            <li>
              <a
                href="https://www.midjourney.com"
                className="text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>MidJourney</strong>
              </a>{" "}
              - for photorealistic image generation.
            </li>
            <li>
              <a
                href="https://hailuoai.video"
                className="text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Hailuo</strong>
              </a>{" "}
              - for animating images into fluid motion.
            </li>
            <li>
              <a
                href="https://www.klingai.com"
                className="text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Kling</strong>
              </a>{" "}
              - for turning AI images into cinematic videos.
            </li>
            <li>
              <a
                href="https://lumalabs.ai"
                className="text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Luma</strong>
              </a>{" "}
              - for advanced video generation and scene creation.
            </li>
            <li>
              <a
                href="https://deepmind.google/technologies/veo/veo-2/"
                className="text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>VEO2</strong>
              </a>{" "}
              - for generating hyper-realistic video shots.
            </li>
            <li>
              <a
                href="https://chat.openai.com"
                className="text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>ChatGPT</strong>
              </a>{" "}
              - for scripting, shot division, and prompt creation.
            </li>
            <li>
              <a
                href="https://elevenlabs.io"
                className="text-blue-400"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>ElevenLabs</strong>
              </a>{" "}
              - for voiceovers and character voice generation.
            </li>
          </ul>
        </section>

        {/* AI Video Creation Process */}
        <div className="mb-4" data-aos="fade-up">
          <h1 className="text-2xl font-semibold mb-4">
            AI Video Creation Process
          </h1>
          <ol className="list-decimal pl-6 space-y-1 [&>li]:text-[16px] lg:[&>li]:text-[18px]">
            <li>Develop the idea and script using ChatGPT.</li>
            <li>
              Use ChatGPT to break down the script into a detailed shot
              division.
            </li>
            <li>
              Start a new ChatGPT thread and provide a custom prompt template.
            </li>
            <li>
              Generate detailed prompts for each shot and create visuals in
              MidJourney.
            </li>
            <li>
              Refine images in MidJourney until high-quality frames are
              achieved.
            </li>
            <li>Convert images into videos using Kling, Hailuo, or Luma.</li>
            <li>Generate voiceovers using ElevenLabs and sync with visuals.</li>
          </ol>
        </div>
        <div
          className="bg-zinc-800 p-4 inline-block text-center mx-auto mt-10 rounded-xl"
          data-aos="fade-up"
        >
          <h1 className="text-3xl font-semibold">Step 1: Get initial shots</h1>
        </div>
        <h1 className="text-2xl font-semibold" data-aos="fade-up">
          Prompt Template
        </h1>
        <div
          className="rounded-lg text-base lg:text-lg  mb-4"
          data-aos="fade-up"
        >
          A photorealistic [time of day] [setting] [camera angle] shot of
          [subject], with [appearance details: physical features, clothing,
          expressions, etc.]. [Background details: environment, textures,
          lighting, mood]. Shot with [camera name] [lens name], [aperture
          setting], [focus type], highlighting [specific elements]. [Lighting
          details]. Featuring [scene enhancements].
        </div>

        {/* Sample Prompt & Result */}

        <h1 className="text-2xl font-semibold" data-aos="fade-up">
          Sample Prompt & Result
        </h1>
        <img src="/assets/KlingKrishna2.jpg" alt="Prompt" data-aos="fade-up" />
        <div
          className="text-base lg:text-lg leading-relaxed mb-4"
          data-aos="fade-up"
        >
          Photorealistic cinematic shot, backshot of a handsome Indian actor
          dressed as Krishna, sitting on a beautifully decorated swing in a lush
          Mahabharat forest set. He has long, well-groomed hair flowing down his
          back, a golden crown with a peacock feather elegantly placed at the
          top. He wears traditional golden ornaments and an intricately
          embroidered yellow dhoti, with a sheer, soft blue dupatta draped over
          his shoulders. His hands hold the flute gracefully, and his posture is
          relaxed yet majestic. The swing is adorned with fresh flowers, jasmine
          garlands, and green vines, gently swaying as he plays the flute. The
          serene backdrop features a picturesque pond with blooming lotus
          flowers, swans gliding across the water, and a tree trunk bridge
          covered in green algae and flowers in the distance. Butterflies
          flutter near vibrant flowers, and sunlight filters through the dense
          forest canopy, casting a soft, golden glow on the scene. Filmed with a
          cinema-grade ARRI Alexa 65 camera, anamorphic lens, 24fps, achieving a
          crisp, high-resolution look with shallow depth of field. The
          foreground is sharp and highly detailed, while the background has a
          soft cinematic blur (bokeh effect). The camera movement is smooth and
          immersive, following the gentle motion of the swing with a slow, wide
          tracking shot, capturing the ambiance and realism of the environment.
        </div>
        <div
          className="bg-zinc-800 p-4 inline-block text-center mx-auto mt-20 rounded-xl"
          data-aos="fade-up"
        >
          <h1 className="text-3xl font-semibold  text-center">
            Step 2: Get videos
          </h1>
        </div>
        <div className="mt-5 mb-5">
          <div className="mb-14 " data-aos="fade-up">
            <h1 className="text-3xl font-semibold text-center mb-6">Kling</h1>
            <div className="flex flex-col md:flex-row justify-center gap-4 items-center">
              <div className="w-full md:w-1/2 max-w-xl h-auto rounded-lg shadow-md">
                <div className="text-xl font-semibold text-center mb-2">
                  Image
                </div>
                <img src="/assets/KlingKrishna2.jpg" alt="Prompt" />
              </div>
              <div className="w-full md:w-1/2 max-w-xl h-auto rounded-lg shadow-md">
                <div className="text-xl font-semibold text-center mb-2">
                  Video
                </div>

                <video
                  // className="w-full md:w-1/2 max-w-xl h-auto rounded-lg shadow-md"
                  src="/assets/KlingKrishna.mp4"
                  alt="Prompt"
                  controls
                />
              </div>
            </div>
            <div className="text-2xl font-semibold text-center mt-4">
              Prompt
            </div>
            <div className="text-base lg:text-lg  font-semibold text-center mt-4">
              A Cinematic shot of the man playing the flute slowly moving his
              fingers, the swing is moving, and due to wind, his feathers is
              also moving along with his hair. Hair and feathers moving in the
              wind
            </div>
          </div>

          <div className="mb-14" data-aos="fade-up">
            <h1 className="text-3xl font-semibold text-center mb-6">Hailuo</h1>
            <div className="flex flex-col md:flex-row justify-center gap-4 items-center">
              <div className="w-full md:w-1/2 max-w-xl h-auto rounded-lg shadow-md">
                <div className="text-xl font-semibold text-center mb-2">
                  Image
                </div>
                <img src="/assets/hourse.jpg" alt="Prompt" />
              </div>
              <div className="w-full md:w-1/2 max-w-xl h-auto rounded-lg shadow-md">
                <div className="text-xl font-semibold text-center mb-2">
                  Video
                </div>
                <video
                  // className="w-full md:w-1/2 max-w-xl h-auto rounded-lg shadow-md"
                  src="/assets/MB_Horse.mp4"
                  alt="Prompt"
                  controls
                />
              </div>
            </div>
            <h1 className="text-2xl font-semibold text-center mt-4">Prompt</h1>
            <div className="text-base lg:text-lg font-semibold text-center mt-4">
              A Cinematic shot of man sitting on horse, add depth, dynamic
              lighting and shadows to the scene
            </div>
          </div>
          <div className="mb-14" data-aos="fade-up">
            <h1 className="text-3xl font-semibold text-center mb-4">
              Google Veo 2
            </h1>
            <div className="flex flex-col md:flex-row justify-center gap-4 items-center">
              <video
                className="w-full  h-auto rounded-lg shadow-md"
                src="/assets/VeoDirector.mp4"
                alt="Prompt"
                controls
              />
            </div>
            <div className="text-2xl font-semibold text-center mt-4">
              Prompt
            </div>
            <div className="text-base lg:text-lg font-semibold text-center mt-4">
              A photorealistic behind-the-scenes front shot of a film director
              with a well-groomed beard and a cap, sitting in his chair on a
              Mahabharata film set. He holds a handheld mic close to his face,
              speaking with focus and authority as he oversees the production.
              His intense expression reflects his concentration, with his eyes
              locked onto the scene unfolding in front of him. In the
              background, sound technicians adjust audio levels and monitor the
              recording through their equipment. The depth of field subtly blurs
              the background, keeping the director as the main focal point while
              still showcasing the immersive behind-the-scenes energy of the
              production
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
