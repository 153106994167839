import { Fragment, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import AdTracking from "./firebase/AdTracking";
import Home from "./pages/Home";
import Movie from "./pages/Movie";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Invite from "./pages/Invite";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import Watch from "./pages/Watch";
import mixpanel from "mixpanel-browser";
import { setError, setLoading, setUser } from "./redux/slice/Auth";
import { api } from "./utils/api";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/init";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Loader from "./components/UI/Loader";
import ProductionTips from "./pages/ProductionTips";
import FilmFest from "./pages/FilmFest";
import {
  allGroups,
  allGroups1,
  allGroups2,
  allGroups3,
  fetchContinueWatch,
} from "./redux/slice/Movie";
import Publish from "./pages/Publish";
import Login from "./components/modal/Login";
import MyVideos from "./pages/MyVideos";
import Videos from "./pages/Videos";
import Trailer from "./pages/Trailer";
import Profile from "./pages/Profile";
import Faq from "./pages/Faq";
import Analytics from "./pages/Analytics";
import NotFound from "./pages/NotFound";
import Search from "./pages/Search";
import { Navigate } from "react-router-dom";
import { openModal } from "./redux/slice/LoginModal";
import AddNewSeason from "./pages/AddNewSeason";
import Views from "./components/analytics/Views";
import Likes from "./components/analytics/Likes";
import DisLikes from "./components/analytics/DisLikes";
import Comments from "./components/analytics/Comments";
import NGL from "./pages/NGL";
import Ffmpeg from "./pages/Ffmpeg";
import UserSharedVideo from "./pages/UserSharedVideo";
function App() {
  const dispatch = useDispatch();
  const { mode, user, loading } = useSelector((state) => state.auth);
  // const { pathname } = useLocation();
  useEffect(() => {
    dispatch(allGroups1());
    dispatch(allGroups2());
    dispatch(allGroups3());
    dispatch(allGroups());
    if (user == null) {
      // dispatch(openModal());
    } else {
      dispatch(fetchContinueWatch());
      mixpanel.identify(user?.id);
      mixpanel.people.set({
        $email: user?.emailID,
        $name: "WEB_" + user?.userName,
      });
      mixpanel.track("User visited");
    }
  }, []);

  // useEffect(() => {
  //   // if (mode === "manuall" || pathname === "/") {
  //   //   console.log("turning off");
  //   //   return;
  //   // }
  //   dispatch(setLoading({ loading: true }));
  //   const unSubscribe = onAuthStateChanged(
  //     auth,
  //     (user) => {
  //       if (user) {
  //         // fetch user based on its uid
  //         api
  //           .post("/auth/check-or-insert", {
  //             emailID: user.email,
  //           })
  //           .then((res) => {
  //             const {
  //               emailID: e,
  //               imageUrl,
  //               userName,
  //               id,
  //               userChatUrl,
  //             } = res.data;
  //             dispatch(
  //               setUser({
  //                 user: { email: e, imageUrl, userName, id, userChatUrl },
  //               })
  //             );
  //             dispatch(fetchContinueWatch());
  //             mixpanel.identify(id);
  //             mixpanel.people.set({
  //               $email: e,
  //               $name: "WEB_" + userName,
  //             });
  //           })
  //           .catch((err) => {
  //             console.log("error", err);
  //             dispatch(setError({ error: err.message }));
  //           });
  //       } else {
  //         dispatch(setLoading({ loading: false }));
  //       }
  //     },
  //     (error) => {
  //       console.log("error", error);
  //       dispatch(setError({ error: error.message }));
  //     }
  //   );

  //   return () => unSubscribe();
  // }, [mode]);

  // if (loading) {
  //   return <Loader message="Loading..." />;
  // }

  return (
    <Fragment>
      <AdTracking />
      <Routes>
        <Route element={<Dashboard />} path="/" />
        <Route element={<Terms />} path="/terms" />
        <Route element={<Privacy />} path="/privacy" />
        <Route element={<NGL />} path="/share/:id" />
        <Route element={<Invite />} path="/invite/:id" />
        <Route element={<Faq />} path="/faq" />
        <Route element={<ProductionTips />} path="/zingroll-production-tips" />
        <Route element={<FilmFest />} path="/film-festival-IITB-25" />
        <Route element={<Search />} path="/search" />
        <Route path="/app" element={<Home />} />
        <Route path="/watch/:name" element={<Watch />} />
        <Route path="/myvideo/:id" element={<UserSharedVideo />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/publish" element={<Publish />} />
          <Route path="/add/:id" element={<AddNewSeason />} />
          <Route element={<MyVideos />} path="/my-videos">
            <Route element={<Navigate to="videos" />} index />
            <Route element={<Trailer />} path="trailer" />
            <Route element={<Videos />} path="videos" />
          </Route>
          <Route path="/profile" element={<Profile />} />
          <Route element={null} path="/analytics">
            <Route element={<Navigate to="index" />} index />
            <Route element={<Analytics />} path="index" />
            <Route element={<Views />} path="views" />
            <Route element={<Likes />} path="likes" />
            <Route element={<DisLikes />} path="dislikes" />
            <Route element={<Comments />} path="comments" />
          </Route>
        </Route>

        <Route element={<Ffmpeg />} path="/ffmpeg" />

        <Route element={<NotFound />} path="*" />
      </Routes>
      <Login />
    </Fragment>
  );
}

export default App;
