import {
  setDescription,
  setName,
  setProfile,
  setPrompt,
  setSeed,
  setVideo,
} from "../../redux/slice/templateGroups/episodeEdit";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useRef, useState } from "react";
import { ImagePlus, Loader, LoaderCircle, X, Copy, Trash } from "lucide-react";
import { api } from "../../utils/api";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { toast } from "react-toastify";
export default function EditGroupModal({ modalRef, modalRef2 }) {
  const dispatch = useDispatch();
  const { name, description, videoUrl, imageUrl, id, prompt, seed } =
    useSelector((state) => state.episodeEdit);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const imageFileRef = useRef();
  const [frames, setFrames] = useState([]);
  const [uploading2, setUploading2] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") dispatch(setName(value));
    else if (name === "description") dispatch(setDescription(value));
    else if (name === "categoryProfile") dispatch(setProfile(value));
    else if (name === "prompt") dispatch(setPrompt(value));
    else if (name === "seed") dispatch(setSeed(value));
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };

  const handleCrop = async () => {
    if (!completedCrop || !canvasRef.current || !imgRef.current) {
      return;
    }
    setSrc(null);
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        const file = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        setUploading(true);
        try {
          const response = await fetch("http://localhost:5432/s3", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              fileName: file.name,
              fileType: file.type,
            }),
          });

          const { uploadUrl, url } = await response.json();
          // console.log(uploadUrl, url);
          // 2️⃣ Upload video directly to S3 using the pre-signed URL
          try {
            const response = await fetch(uploadUrl, {
              method: "PUT",
              headers: { "Content-Type": file.type },
              body: file,
            });
          } catch (error) {
            console.error(error);
            alert("Upload failed.");
          }
          console.log(url);

          dispatch(setProfile(url));
        } catch (error) {
          toast.error("error uploading image");
          console.log(error);
        } finally {
          setUploading(false);
        }
      },
      "image/jpeg",
      1
    );
  };
  const handleFileChange = async (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader?.addEventListener("load", () => setSrc(reader.result));
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const resetFileInput = () => {
    dispatch(setProfile(null));
    if (imageFileRef.current) {
      imageFileRef.current.value = "";
      imageFileRef.current.files = null;
    }
  };

  const handleSave = async () => {
    if (!imageUrl) return alert("Please upload an image");

    setLoading(true);
    try {
      const payload = {
        name,
        description,
        imageUrl,
        prompt,
        seed,
      };
      // console.log(
      //   JSON.stringify(
      //     {
      //       id,
      //       groupData: payload,
      //     },
      //     null,
      //     2
      //   )
      // );
      const update = await api.post(
        "/videotemplate/update-video-template-data",
        {
          id: id,
          videoTemplateData: payload,
        }
      );
      // console.log("updated", update);
      modalRef.current.close();
      modalRef2.current.close();
      toast.success("Episode updated successfully");
    } catch (error) {
      toast.error("error updating episode");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(videoUrl);
    toast.success("Link copied to clipboard!");
  };

  const uploadToS3 = async (file, uploadUrl, url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", uploadUrl, true);
      xhr.setRequestHeader("Content-Type", file.type);

      // Track Upload Progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percent = Math.round((event.loaded / event.total) * 100);
          setProgress(percent); // Update UI with progress
        }
      };

      // On Success
      xhr.onload = () => {
        if (xhr.status === 200) {
          setUploading2(false);
          dispatch(setVideo(url));
        } else {
          reject("Upload failed.");
        }
      };

      // On Error
      xhr.onerror = () => reject("Upload error.");

      xhr.send(file); // Send file to S3
    });
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      try {
        setUploading2(true);
        // 1️⃣ Request a pre-signed URL from backend
        const response = await fetch("http://localhost:5432/s3", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ fileName: file.name, fileType: file.type }),
        });

        const { uploadUrl, url } = await response.json();
        // console.log(uploadUrl, url);
        // 2️⃣ Upload video directly to S3 using the pre-signed URL
        try {
          await uploadToS3(file, uploadUrl, url);
        } catch (error) {
          console.error(error);
          alert("Upload failed.");
        }
        setUploading2(false);

        alert("Upload successful!");
      } catch (error) {
        console.error("Upload failed", error);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const handleFileChangeNew = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    try {
      setUploading2(true);
      // 1️⃣ Request a pre-signed URL from backend
      const response = await fetch("http://localhost:5432/s3", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fileName: file.name, fileType: file.type }),
      });

      const { uploadUrl, url } = await response.json();
      // console.log(uploadUrl, url);
      // 2️⃣ Upload video directly to S3 using the pre-signed URL
      try {
        await uploadToS3(file, uploadUrl, url);
      } catch (error) {
        console.error(error);
        alert("Upload failed.");
      }
      setUploading2(false);

      alert("Upload successful!");
    } catch (error) {
      console.error("Upload failed", error);
    }
  };
  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 overflow-auto"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-900 p-8 h-[90%] rounded-lg shadow-lg max-w-5xl w-full relative overflow-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between border-b-2 border-zinc-700">
            <h1 className="text-xl font-bold mb-4">Edit Episode</h1>
            <X
              onClick={() => modalRef.current.close()}
              size={25}
              className="cursor-pointer"
            />
          </div>

          <div className="flex flex-col-reverse md:flex-row gap-5 mt-5">
            {/* Left Column - Form */}
            <div className="flex flex-col w-full">
              <h1 className="text-xl font-bold mb-4">Video Details</h1>
              <form>
                <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                  required
                />

                <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                  Description
                </label>
                <textarea
                  name="description"
                  value={description}
                  rows="3"
                  onChange={handleChange}
                  className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                  required
                />

                <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                  Prompt
                </label>
                <textarea
                  name="prompt"
                  value={prompt}
                  rows="2"
                  placeholder="Please provide prompts & tools that you used for creating this. Otherwise, royalties might not apply"
                  onChange={handleChange}
                  className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                  required
                />

                <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                  Seed
                </label>
                <input
                  type="text"
                  name="seed"
                  value={seed}
                  placeholder="Please provide seeds. Otherwise, royalties might not apply"
                  className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                  required
                  onChange={handleChange}
                />

                <button
                  type="button"
                  onClick={handleSave}
                  className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 mt-4"
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <LoaderCircle className="animate-spin mr-2" size={22} />
                    </div>
                  ) : (
                    "Update"
                  )}
                </button>
              </form>
            </div>

            {/* Right Column - Video & Image Upload */}
            <div className="flex flex-col md:w-1/3">
              {videoUrl ? (
                <>
                  <div className="flex justify-between border-b-2 border-zinc-700">
                    <h1 className="text-xl font-bold mb-4">Video Preview</h1>
                    <Trash
                      className="cursor-pointer"
                      size={20}
                      onClick={() => dispatch(setVideo(null))}
                    />
                  </div>
                  <video className="w-full mb-4" controls src={videoUrl} />
                </>
              ) : (
                <div className="flex flex-col mb-5">
                  <h1 className="text-xl font-bold mb-4">Upload Show</h1>

                  {uploading2 ? (
                    <>
                      <div className="text-gray-500 text-sm flex gap-2 mt-10 mb-2 w-full items-center justify-center">
                        uploading Show
                        <LoaderCircle className="animate-spin" size={14} />
                      </div>
                      <div className="flex items-center gap-2 w-full">
                        <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden ">
                          <div
                            style={{ width: progress + "%" }}
                            className="h-full w-full rounded-full bg-blue-400"
                          />
                        </div>
                        <span className="font-bold">{progress}%</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center cursor-pointer h-40 flex items-center justify-center w-full"
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onClick={() =>
                          document.getElementById("file-input").click()
                        }
                      >
                        <p className="text-gray-500">
                          Drag & drop your Show here or click to select
                        </p>
                      </div>
                      <input
                        type="file"
                        accept="video/*"
                        onChange={handleFileChangeNew}
                        style={{ display: "none" }}
                        id="file-input"
                      />
                      <label
                        htmlFor="file-input"
                        className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 cursor-pointer block text-center mt-4"
                      >
                        Select Show
                      </label>
                    </>
                  )}
                </div>
              )}

              <div className="flex justify-between items-center mt-4 mb-2">
                <h1 className="text-xl font-bold">Thumbnail</h1>
                {imageUrl && (
                  <Trash
                    className="cursor-pointer"
                    size={20}
                    onClick={resetFileInput}
                  />
                )}
              </div>

              {/* Existing thumbnail section */}
              {imageUrl ? (
                <div className="relative">
                  <img
                    src={imageUrl}
                    alt="Episode Preview"
                    className="w-full aspect-video"
                  />
                  <button
                    onClick={resetFileInput}
                    className="absolute top-2 right-2 bg-zinc-700 rounded-full p-1 hover:bg-zinc-800"
                  >
                    <X size={20} />
                  </button>
                </div>
              ) : src ? (
                <div>
                  <ReactCrop
                    crop={crop}
                    onChange={(newCrop) => setCrop(newCrop)}
                    onComplete={(c) => setCompletedCrop(c)}
                    aspect={16 / 9}
                  >
                    <img
                      ref={imgRef}
                      alt="Crop me"
                      src={src}
                      onLoad={onImageLoad}
                      className="max-w-full"
                    />
                  </ReactCrop>
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <div className="flex gap-2">
                    <button
                      onClick={() => setSrc(null)}
                      className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleCrop()}
                      className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                    >
                      Crop & Upload
                    </button>
                  </div>
                </div>
              ) : (
                <label htmlFor="image-input" className="cursor-pointer">
                  <div className="flex items-center text-center justify-center w-full h-40 border-2 border-dashed border-zinc-600 rounded-lg hover:bg-zinc-800">
                    {uploading ? (
                      <>
                        <LoaderCircle className="animate-spin mr-2" />
                        <span>Uploading...</span>
                      </>
                    ) : (
                      "Upload Thumbnail"
                    )}
                  </div>
                  <input
                    disabled={uploading}
                    onChange={handleFileChange}
                    id="image-input"
                    ref={imageFileRef}
                    type="file"
                    accept="image/*"
                    className="hidden"
                  />
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
}
