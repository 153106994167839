import React, { useState } from "react";
import { ChevronLeft, ChevronDown, ChevronUp } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Views() {
  const navigate = useNavigate();
  const {
    loading,
    data: adata,
    series,
  } = useSelector((state) => state.analytics);

  const seriesData = {};
  series.map((e) => {
    const da = adata.filter((item) => item.templateGroupID == e.id);
    const diffSeasons = [...new Set(da.map((item) => item.seriesSeason))].sort(
      (a, b) => a - b
    );
    diffSeasons.map((f) => {
      const episodes = da.filter((item) => item.seriesSeason == f);
      const totalViews = episodes.reduce((acc, curr) => acc + curr.views, 0);
      const totalComments = episodes.reduce(
        (acc, curr) => acc + curr.comments,
        0
      );
      const totalLikes = episodes.reduce((acc, curr) => acc + curr.likes, 0);
      const totalDislikes = episodes.reduce(
        (acc, curr) => acc + curr.dislikes,
        0
      );
      seriesData[e.name] = {
        ...seriesData[e.name],
        [f]: {
          episodes,
          totalViews,
          totalComments,
          totalLikes,
          totalDislikes,
        },
      };
    });
    seriesData[e.name] = {
      ...seriesData[e.name],
      totalViews: da.reduce((acc, curr) => acc + curr.views, 0) || 0,
      totalComments: da.reduce((acc, curr) => acc + curr.comments, 0) || 0,
      totalLikes: da.reduce((acc, curr) => acc + curr.likes, 0) || 0,
      totalDislikes: da.reduce((acc, curr) => acc + curr.dislikes, 0) || 0,
    };
  });

  const [expandedSeries, setExpandedSeries] = useState({});
  const [expandedSeasons, setExpandedSeasons] = useState({});

  const toggleSeries = (seriesName) => {
    setExpandedSeries((prev) => ({
      ...prev,
      [seriesName]: !prev[seriesName],
    }));
  };

  const toggleSeason = (seriesName, season) => {
    const key = `${seriesName}-${season}`;
    setExpandedSeasons((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const getSeriesChartData = (seriesInfo) => {
    // Collect all episodes from all seasons
    const allEpisodes = Object.entries(seriesInfo)
      .filter(
        ([key]) =>
          ![
            "totalViews",
            "totalComments",
            "totalLikes",
            "totalDislikes",
          ].includes(key)
      )
      .reduce((acc, [season, data]) => {
        return [
          ...acc,
          ...data.episodes.map((ep) => ({
            ...ep,
            season: season,
          })),
        ];
      }, [])
      .sort((a, b) => {
        // Sort by season and episode number
        if (a.seriesSeason !== b.seriesSeason) {
          return a.seriesSeason - b.seriesSeason;
        }
        return a.episodeNumber - b.episodeNumber;
      });

    return {
      labels: allEpisodes.map(
        (ep) => `S${ep.seriesSeason}E${ep.episodeNumber}`
      ),
      datasets: [
        {
          label: "Views",
          data: allEpisodes.map((ep) => ep.views),
          borderColor: "rgb(75, 192, 192)",
          tension: 0.1,
        },
        {
          label: "Likes",
          data: allEpisodes.map((ep) => ep.likes),
          borderColor: "rgb(54, 162, 235)",
          tension: 0.1,
        },
        {
          label: "Comments",
          data: allEpisodes.map((ep) => ep.comments),
          borderColor: "rgb(255, 206, 86)",
          tension: 0.1,
        },
      ],
    };
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Series Performance Metrics",
        color: "white",
      },
    },
    scales: {
      y: {
        ticks: { color: "white" },
        grid: { color: "rgba(255, 255, 255, 0.1)" },
      },
      x: {
        ticks: {
          color: "white",
          maxRotation: 45,
          minRotation: 45,
        },
        grid: { color: "rgba(255, 255, 255, 0.1)" },
      },
    },
  };

  return (
    <div className="mt-5">
      <div className="flex items-center px-4 lg:px-6">
        <span
          className="bg-zinc-800 p-2 rounded-full cursor-pointer hover:bg-zinc-700"
          onClick={() => navigate(-1)}
        >
          <ChevronLeft size={22} />
        </span>
        <h1 className="text-3xl font-bold text-center m-auto">Views</h1>
      </div>
      <div className="shadow-md rounded-lg p-4 bg-zinc-800 m-4 mt-5">
        {Object.entries(seriesData).map(([seriesName, seriesInfo]) => (
          <div key={seriesName} className="mb-4">
            <div
              className="flex items-center justify-between p-3 bg-zinc-700 rounded-lg cursor-pointer hover:bg-zinc-600"
              onClick={() => toggleSeries(seriesName)}
            >
              <h2 className="text-xl font-semibold">{seriesName}</h2>
              {expandedSeries[seriesName] ? (
                <ChevronUp size={20} />
              ) : (
                <ChevronDown size={20} />
              )}
            </div>

            {expandedSeries[seriesName] && (
              <div className="ml-4 mt-2">
                <div className="p-2 bg-zinc-700 rounded-lg mb-2">
                  <p className="font-semibold">Series Totals:</p>
                  <p>Total Views: {seriesInfo.totalViews}</p>
                  <p>Total Comments: {seriesInfo.totalComments}</p>
                  <p>Total Likes: {seriesInfo.totalLikes}</p>
                  <p>Total Dislikes: {seriesInfo.totalDislikes}</p>
                </div>

                <div className="mt-4 mb-4 p-4 bg-zinc-700 rounded-lg">
                  <h3 className="font-semibold mb-2">
                    Shows Performance Overview
                  </h3>
                  <div className="bg-zinc-800 p-4 rounded-lg">
                    <Line
                      data={getSeriesChartData(seriesInfo)}
                      options={chartOptions}
                    />
                  </div>
                </div>

                {Object.entries(seriesInfo).map(([key, value]) => {
                  if (
                    key !== "totalViews" &&
                    key !== "totalComments" &&
                    key !== "totalLikes" &&
                    key !== "totalDislikes"
                  ) {
                    return (
                      <div key={key} className="mb-2">
                        <div
                          className="flex items-center justify-between p-2 bg-zinc-700 rounded-lg cursor-pointer hover:bg-zinc-600"
                          onClick={() => toggleSeason(seriesName, key)}
                        >
                          <h3 className="font-semibold">Season {key}</h3>
                          {expandedSeasons[`${seriesName}-${key}`] ? (
                            <ChevronUp size={16} />
                          ) : (
                            <ChevronDown size={16} />
                          )}
                        </div>

                        {expandedSeasons[`${seriesName}-${key}`] && (
                          <div className="ml-4 mt-2 p-2 bg-zinc-700 rounded-lg">
                            <p>Views: {value.totalViews || 0}</p>
                            <p>Comments: {value.totalComments || 0}</p>
                            <p>Likes: {value.totalLikes || 0}</p>
                            <p>Dislikes: {value.totalDislikes || 0}</p>

                            <div className="mt-2">
                              <p className="font-semibold">Episodes:</p>
                              <div className="ml-2">
                                {value.episodes.map((episode, index) => (
                                  <div
                                    key={index}
                                    className="p-2 bg-zinc-600 rounded-lg mt-1"
                                  >
                                    <div className="font-semibold mb-1">
                                      S{episode.seriesSeason}E
                                      {episode.episodeNumber} -{" "}
                                      {episode.name ||
                                        `Episode ${episode.episodeNumber}`}
                                    </div>
                                    <p>Views: {episode.views || 0}</p>
                                    <p>Comments: {episode.comments || 0}</p>
                                    <p>Likes: {episode.likes || 0}</p>
                                    <p>Dislikes: {episode.dislikes || 0}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Views;
