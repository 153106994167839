import Footer from "../components/Footer";
import Loader from "../components/UI/Loader";
import ErrorMessage from "../components/UI/ErrorMEssage";
import Player from "../components/player/Player";
import Episodes from "../components/Episodes";
import Header from "../components/Header";
import Trailer from "../components/Trailer";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { getEpisodes } from "../redux/slice/Movie";
// import Comments from "../components/Comments";
import PlayerBottom from "../components/PlayerBottom";
import mixpanel from "mixpanel-browser";
import { useNavigate, useParams } from "react-router-dom";
import { closeModal, openModal } from "../redux/slice/LoginModal";
import FbPixel from "../utils/fbPixel";
import { api } from "../utils/api";
export default function Movie() {
  const { group, episodes, movie, error, movieLoading } = useSelector(
    (state) => state.movie
  );
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const { name } = useParams();
  const dispatch = useDispatch();
  const { id, item } = state || {};
  useEffect(() => {
    if (user == null) {
      if (
        name == "mahabharat-an-eternal-war" ||
        name == "newton-cradle-by-jeff"
      ) {
        dispatch(
          openModal({
            modalType: name,
          })
        );
      } else {
        setTimeout(() => {
          dispatch(openModal());
        }, 15000);
      }
    }
    if (!id) {
      fetchData();
    } else {
      dispatch(getEpisodes({ id, item: item }));
    }
    mixpanel.track("Watched a video", {
      id,
      title: item?.trailer?.name || item?.title,
    });
    FbPixel("Watched a video", {
      name: item?.trailer?.name || item?.title,
    });

    return () => {
      dispatch(closeModal());
    };
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const pay = name
        ?.split("-")[0]
        .replace(/^./, (char) => char.toUpperCase());
      const res = await api.post("/templategroup/group-search", {
        text: pay,
      });
      const data = res.data.users.filter((e) => e.name.includes(pay));
      // console.log("data", data[0]);
      dispatch(getEpisodes({ id: data[0].id, item: data[0] }));
    } catch (error) {
      console.log("error in fetching episodes", error);
    }
    setLoading(false);
  };

  if (error) {
    return <ErrorMessage message={error} />;
  }
  return (
    <div>
      <Header />
      {movieLoading || loading ? (
        <Loader message="loading..." />
      ) : (
        <>
          <Player movie={movie} />
          {/* <PlayerBottom movie={movie} item={item} /> */}
          {group?.trailer?.videoUrl && group?.trailer?.imageUrl && <Trailer />}
          {episodes?.length > 0 && <Episodes />}
          {/* <Comments id={id} item={item} /> */}
          <Footer />
        </>
      )}
    </div>
  );
}
