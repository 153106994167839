import React from "react";
import { Link } from "react-router-dom";
import { Instagram, Facebook } from "lucide-react";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../redux/slice/LoginModal";
export default function Footer() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  return (
    <>
      <div className="p-6 mt-6 sm:mt-20 text-white flex flex-col md:flex-row justify-between w-full max-w-6xl mx-auto items-start sm:items-center">
        <div className="flex flex-col gap-2 items-center justify-center m-auto mb-6 md:m-0">
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/zingroll-movies-about-you/id6466747851"
            className="self-center px-12 py-4 rounded-2xl flex-col gap-3 items-center bg-[#272727] hover:bg-[#3a3a3a] transition duration-300"
          >
            <img
              src={"/svg/apple.svg"}
              className="h-10 m-auto"
              alt="App Store"
            />
            <div className="flex flex-col m-auto mt-2">
              <span className="text-sm text-center">Download on the</span>
              <span className="font-semibold text-lg text-center">
                App Store
              </span>
            </div>
          </a>
          <div className="flex items-center gap-1">
            <span
              className="p-2 hover:bg-zinc-800 rounded-xl cursor-pointer"
              onClick={() =>
                window.open("https://www.instagram.com/zingroll/", "_blank")
              }
            >
              <Instagram size={25} />
            </span>
            <span
              className="p-2 hover:bg-zinc-800 rounded-xl cursor-pointer"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/profile.php?id=61572748236872",
                  "_blank"
                )
              }
            >
              <Facebook size={25} />
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-4 m-0 sm:flex-row sm:gap-16 sm:m-auto md:m-0">
          <div className="flex flex-col">
            <h4 className="font-semibold mb-2">Quick Links</h4>
            <Link
              to="/terms"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Terms and Conditions
            </Link>
            <Link
              to="/privacy"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Privacy Policy
            </Link>
            <Link
              to="/zingroll-production-tips"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Production Tips
            </Link>
            <Link
              to="/faq"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              FAQ
            </Link>
          </div>
          {/* <div className="flex flex-col">
            <h4 className="font-semibold mb-2">Support</h4>
            <Link
              to="/help"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Help Centre Support
            </Link>
            <Link
              to="/account"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Manage Your Account
            </Link>
            <Link
              to="/faq"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              FAQs
            </Link>
          </div> */}
          <div className="flex flex-col">
            <h4 className="font-semibold mb-2">Your Account</h4>
            {/* <Link
              to="/"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Home
            </Link> */}
            <Link
              onClick={() => {
                if (user == null) dispatch(openModal());
              }}
              to="/profile"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Profile
            </Link>
            <Link
              onClick={() => {
                if (user == null) dispatch(openModal());
              }}
              to="/my-videos"
              className="text-sm text-[#b0b0b0] hover:text-white transition duration-300 mb-2"
            >
              Titles
            </Link>
          </div>
        </div>
      </div>
      <div className="flex-1 text-center mb-4 py-2">
        <span className="text-[#777777] text-sm">
          Copyright © 2025, Harshit Foundation
        </span>
      </div>
    </>
  );
}
