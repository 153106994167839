import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import UploadVideo from "../components/AddNewSeason2/uploadVideo";
import Details from "../components/AddNewSeason2/Details";
import { useSelector, useDispatch } from "react-redux";
import TimeLine from "../components/AddNewSeason2/tileLine";
import { getSavedData } from "../redux/slice/Upload2";
function AddNewSeason({ id }) {
  const { state } = useLocation();
  const { data } = state || {};
  const { step, series } = useSelector((state) => state.upload2);

  const renderSteps = () => {
    if (step == 1) return null;
    else if (step == 2) return <UploadVideo data={data} />;
    else if (step == 3) return <Details groupId={id} data={data} />;
    else return <UploadVideo />;
  };

  return (
    <div className="flex items-center justify-center h-screen sm:m-4 overflow-hidden">
      <div className="rounded-xl shadow-md w-full max-w-5xl h-full relative overflow-scroll">
        {renderSteps()}
      </div>
    </div>
  );
}

export default AddNewSeason;
