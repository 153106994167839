import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics, logEvent } from "./init";

const getUTMParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    utm_source: urlParams.get("utm_source") || "direct",
    utm_medium: urlParams.get("utm_medium") || "none",
    utm_campaign: urlParams.get("utm_campaign") || "unknown",
  };
};

const AdTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const utmParams = getUTMParams();
    console.log("Logging event:", utmParams); // Debugging log

    if (analytics) {
      logEvent(analytics, `ad_referral_${utmParams.utm_source}`, {
        page_location: window.location.href,
        page_referrer: document.referrer || "direct",
        utm_source: utmParams.utm_source,
        utm_medium: utmParams.utm_medium,
        utm_campaign: utmParams.utm_campaign,
      });
    }
  }, []);

  return null;
};

export default AdTracking;
