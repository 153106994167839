import { Fragment, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import TabNavBar from "../components/UI/TabNavBar";
import { List, CirclePlus, Pen, Video, Clapperboard } from "lucide-react";
import mixpanel from "mixpanel-browser";
import FbPixel from "../utils/fbPixel";
import Videos from "./Videos";
export default function Templates() {
  useEffect(() => {
    mixpanel.track("Navigate to My Videos Page");
    FbPixel("Navigate to My Videos Page");
  }, []);
  return <Videos />;
}
