import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../utils/api";

export const fetchEpisodes = createAsyncThunk(
  "fetchEpisode",
  async ({ templateGroupID, name, categoryID, imageUrl, userId }) => {
    try {
      const { searchData: data } = (
        await api.post("/videotemplate/get-template-group-search-data", {
          templateGroupID,
          limit: 2000,
        })
      ).data;
      data.sort((a, b) => parseInt(a.episodNumber) - parseInt(b.episodNumber));
      return {
        episodes: data,
        name,
        templateGroupID,
        categoryID,
        imageUrl,
        userId,
      };
    } catch (err) {
      throw err;
    }
  }
);

const episodeSlice = createSlice({
  name: "episodeList",
  initialState: {
    data: null,
    loading: false,
    error: "",
  },

  extraReducers: (builder) => {
    builder.addCase(fetchEpisodes.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = "";
      state.loading = false;
    });
    builder.addCase(fetchEpisodes.rejected, (state, action) => {
      state.data = null;
      state.error = action.error.message;
      state.loading = false;
    });
    builder.addCase(fetchEpisodes.pending, (state, action) => {
      state.data = null;
      state.error = "";
      state.loading = true;
    });
  },
});

export default episodeSlice.reducer;
