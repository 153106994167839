import {
  setDescription,
  setName,
  setProfile,
  setStatus,
  setCategory,
  resetTemplate,
  setTrailerProgressBar,
  setTrailerProgressNumber,
} from "../../redux/slice/templateGroups/groupEdit";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useRef, useState } from "react";
import { ImagePlus, Loader, LoaderCircle, Trash, X } from "lucide-react";
import { api } from "../../utils/api";
import { editSeries } from "../../redux/slice/Upload";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { toast } from "react-toastify";
export default function EditGroupModal({ modalRef }) {
  const dispatch = useDispatch();
  const {
    name,
    description,
    status,
    templateGroupProfile,
    categoryID,
    categoryLabel,
    id,
    videoUrl,
    isTrailer,
    trailerProgressNumber,
    trailerProgressBar,
    trailer,
  } = useSelector((state) => state.groupEdit);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const imageInputRef = useRef();
  const imageFileRef = useRef();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") dispatch(setName(value));
    else if (name === "description") dispatch(setDescription(value));
    else if (name === "categoryProfile") dispatch(setProfile(value));
    else if (name === "status") dispatch(setStatus(value));
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };

  const handleCrop = async () => {
    if (!completedCrop || !canvasRef.current || !imgRef.current) {
      return;
    }
    setSrc(null);
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        const file = new File([blob], "cropped-image.jpg", {
          type: "image/jpeg",
        });
        setUploading(true);
        try {
          const response = await fetch("http://localhost:5432/s3", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              fileName: file.name,
              fileType: file.type,
            }),
          });

          const { uploadUrl, url } = await response.json();
          // console.log(uploadUrl, url);
          // 2️⃣ Upload video directly to S3 using the pre-signed URL
          try {
            // await uploadToS3(file, uploadUrl, url);
            const response = await fetch(uploadUrl, {
              method: "PUT",
              headers: { "Content-Type": file.type },
              body: file,
            });
          } catch (error) {
            console.error(error);
            alert("Upload failed.");
          }
          console.log(url);

          dispatch(setProfile(url));
        } catch (error) {
          toast.error("error uploading image");
          console.log(error);
        } finally {
          setUploading(false);
        }
      },
      "image/jpeg",
      1
    );
  };
  const handleFileChange = async (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader?.addEventListener("load", () => setSrc(reader.result));
      reader?.readAsDataURL(e.target.files[0]);
    }
  };

  const resetFileInput = () => {
    dispatch(setProfile(null));
    if (imageInputRef.current) {
      imageInputRef.current.value = "";
    }
    if (imageFileRef.current) {
      imageFileRef.current.value = "";
      imageFileRef.current.files = null;
    }
  };

  const deleteTrailer = async () => {
    api.post("/templategroup/update-template-group-data", {
      id,
      groupData: {
        trailer: null,
      },
    });
    dispatch(
      editSeries({
        id,
        data: {
          trailer: null,
        },
      })
    );
    modalRef.current.close();
    toast.success("Trailer Deleted successfully");
  };
  const handleSave = async () => {
    setLoading(true);
    console.log(isTrailer);
    try {
      if (isTrailer) {
        const res = await api.post(
          "/templategroup/update-template-group-data",
          {
            id,
            groupData: {
              trailer: {
                name,
                description,
                imageUrl: templateGroupProfile,
                videoUrl,
              },
              trailerProgressNumber,
              trailerProgressBar,
            },
          }
        );
        dispatch(editSeries({ id, data: res.data.updatedData }));
        dispatch(resetTemplate());
        modalRef.current.close();
        toast.success("Trailer updated successfully");
      } else {
        const payload = {
          name,
          description,
          status,
          templateGroupProfile,
          prompt: "",
          type: "user-groups",
          categoryID,
          categoryLabel,
        };

        console.log(
          JSON.stringify(
            {
              id,
              groupData: payload,
            },
            null,
            2
          )
        );
        const res = await api.post(
          "/templategroup/update-template-group-data",
          {
            id,
            groupData: payload,
          }
        );
        dispatch(editSeries({ id, data: payload }));
        dispatch(resetTemplate());
        modalRef.current.close();
        toast.success("Show updated successfully");
      }
    } catch (error) {
      toast.error("error updating show");
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getCateId = (e) => {
    if (e.target.value == "Educational") {
      dispatch(
        setCategory({
          id: "641f1c52-b071-469c-b3e3-fdbf0766fff9",
          name: "Educational",
        })
      );
    } else if (e.target.value == "romance") {
      dispatch(
        setCategory({
          id: "d40c6afd-a392-4f5d-8f8a-18db20fbcbe6",
          name: "romance",
        })
      );
    } else if (e.target.value == "Thriller") {
      dispatch(
        setCategory({
          id: "f8dba1f3-4286-4266-a04b-22f16f6027db",
          name: "Thriller",
        })
      );
    } else if (e.target.value == "SciFi") {
      dispatch(
        setCategory({
          id: "38cc137a-b595-42d5-a22f-617c80f0937f",
          name: "SciFi",
        })
      );
    } else if (e.target.value == "comedy") {
      dispatch(
        setCategory({
          id: "d3334e83-51a9-4a99-bd97-0affceb95965",
          name: "comedy",
        })
      );
    } else if (e.target.value == "horror") {
      dispatch(
        setCategory({
          id: "7b39a208-0add-49ce-a4ae-c30d45ee4a67",
          name: "horror",
        })
      );
    } else if (e.target.value == "adventure") {
      dispatch(
        setCategory({
          id: "c03c2914-66c7-4486-8a23-7b0f68bb3853",
          name: "adventure",
        })
      );
    } else if (e.target.value == "drama") {
      dispatch(
        setCategory({
          id: "f7040bf9-a8a8-4244-aaef-6d58f28484d4",
          name: "drama",
        })
      );
    } else if (e.target.value == "inspirational") {
      dispatch(
        setCategory({
          id: "d060505b-31de-44e6-a6c0-b472537e9cda",
          name: "inspirational",
        })
      );
    } else {
      dispatch(
        setCategory({
          id: "7b2ad184-0cda-487e-af0a-55d9dd0b3720",
          name: "others",
        })
      );
    }
  };

  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-900 p-8 rounded-lg shadow-lg max-w-2xl w-full relative"
          onClick={(e) => e.stopPropagation()}
        >
          <X
            onClick={() => modalRef.current.close()}
            size={25}
            className="cursor-pointer absolute top-4 right-4"
          />
          <h2 className="text-xl font-semibold mb-6">
            {isTrailer ? "Edit Trailer" : "Edit Show"}
          </h2>
          <div className="gap-4 ">
            <div className="flex flex-col mb-2">
              {templateGroupProfile ? (
                <div className="relative w-fit mx-auto mb-4 ">
                  <img
                    src={templateGroupProfile}
                    alt="Category Preview"
                    className="w-40 h-40 object-cover rounded-md"
                  />
                  <button
                    onClick={resetFileInput}
                    className="absolute -top-2 -right-2 bg-zinc-700 rounded-full p-1 hover:bg-zinc-800"
                  >
                    <X />
                  </button>
                </div>
              ) : src ? (
                <div>
                  <div className="w-fit mx-auto ">
                    <ReactCrop
                      crop={crop}
                      onChange={(newCrop) => setCrop(newCrop)}
                      onComplete={(c) => setCompletedCrop(c)}
                      aspect={16 / 9}
                    >
                      <img
                        ref={imgRef}
                        alt="Crop me"
                        src={src}
                        onLoad={onImageLoad}
                        className="w-52 h-52 "
                      />
                    </ReactCrop>
                  </div>
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                  <div className="flex gap-2 max-w-sm mx-auto">
                    <button
                      onClick={() => setSrc(null)}
                      className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleCrop()}
                      className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                    >
                      Crop & Upload
                    </button>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <label
                    htmlFor="image-input"
                    className="flex cursor-pointer h-24 flex-col items-center justify-center border rounded-xl border-dashed grow"
                  >
                    {uploading ? (
                      <Fragment>
                        <LoaderCircle className="animate-spin" />
                        <span>uploading your image</span>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <ImagePlus size={30} />
                        <span>select an image</span>
                      </Fragment>
                    )}
                  </label>
                  <input
                    disabled={uploading}
                    onChange={handleFileChange}
                    id="image-input"
                    ref={imageFileRef}
                    type="file"
                    accept="image/*"
                    className="absolute -top-[9999px]"
                  />
                </Fragment>
              )}
            </div>

            <div className="flex flex-col gap-4">
              {/* name and status */}
              <div className="flex gap-3">
                {/* name */}
                <div className="space-y-1 grow">
                  <label className="block  font-medium">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    className="block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none"
                    placeholder="Enter category name"
                  />
                </div>
              </div>

              {/* decription */}
              <div className="gap-1 flex flex-col grow">
                <label className="block  font-medium">Description</label>
                <textarea
                  name="description"
                  value={description}
                  onChange={handleChange}
                  className=" block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none"
                  placeholder="Enter description"
                />
              </div>
              {isTrailer && (
                <div className="mb-5">
                  <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
                    Trailer Reach Count
                  </label>
                  <div className="flex gap-2">
                    <div className="flex items-center ml-2">
                      <input
                        type="checkbox"
                        id="trailerCount"
                        className="block w-5 h-5 border-2 border-zinc-600 rounded-md p-3  bg-zinc-800 focus:border-blue-500 outline-none hover:cursor-pointer"
                        onChange={(e) =>
                          dispatch(setTrailerProgressBar(e.target.checked))
                        }
                        checked={trailerProgressBar}
                      />
                      <label
                        for="trailerCount"
                        className="ml-2 text-sm text-gray-400"
                      >
                        {trailerProgressBar ? "Enabled" : "Disabled"}
                      </label>
                    </div>
                    {trailerProgressBar && (
                      <input
                        id="trailerCount"
                        type="text"
                        placeholder="Trailer Reach Count"
                        value={
                          trailerProgressNumber ? trailerProgressNumber : ""
                        }
                        className="mb-2 block w-full border-2  border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none"
                        required
                        onChange={(e) =>
                          dispatch(setTrailerProgressNumber(e.target.value))
                        }
                      />
                    )}
                  </div>
                </div>
              )}
              {!isTrailer && (
                <div className="gap-1 flex flex-col grow">
                  <label className="block font-medium">Category</label>
                  <select
                    className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-900 focus:border-blue-500 outline-none"
                    value={categoryLabel}
                    onChange={(e) => getCateId(e)}
                    required
                  >
                    <option value="">Select an category</option>
                    <option value="Educational">Educational</option>
                    <option value="romance">Romance</option>
                    <option value="Thriller">Thriller</option>
                    <option value="SciFi">SciFi</option>
                    <option value="comedy">Comedy</option>
                    <option value="horror">Horror</option>
                    <option value="adventure">Adventure</option>
                    <option value="drama">Drama</option>
                    <option value="inspirational">Inspirational</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              )}
            </div>
          </div>

          <div className="flex gap-2 justify-end">
            <button
              onClick={handleSave}
              className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-full mt-6"
            >
              {loading ? (
                <Loader size={25} className="animate-spin m-auto" />
              ) : isTrailer ? (
                "Update Trailer"
              ) : (
                "Update Show"
              )}
            </button>

            {isTrailer && (
              <button
                onClick={deleteTrailer}
                className="bg-red-400 p-2 rounded-lg mt-6"
              >
                <Trash size={25} />
              </button>
            )}
          </div>
        </div>
      </div>
    </dialog>
  );
}
