import { PlayCircle, ChevronDown, ChevronUp } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setMovie } from "../redux/slice/Movie";

export default function Episodes() {
  const dispatch = useDispatch();
  const { episodes, movie } = useSelector((state) => state.movie);
  const [season, setSeason] = useState(1);
  const [seasonEpisodes, setSeasonEpisodes] = useState([]);
  const [otherSeasonEpisodes, setOtherSeasonEpisodes] = useState([]);
  const [expandedSeasons, setExpandedSeasons] = useState({});
  // const diffSeason = [
  //   ...new Set(
  //     episodes
  //       // .filter((item) => item.type === "original")
  //       .map((item) => item.seriesSeason)
  //   ),
  // ].sort((a, b) => a - b);

  const diffSeason = [
    ...new Set(episodes.map((item) => item.seriesSeason)),
  ].sort((a, b) => a - b);

  useEffect(() => {
    window.scrollTo({ top: 80, behavior: "smooth" });
  }, [movie]);

  useEffect(() => {
    setSeasonEpisodes(
      episodes.filter(
        (item) => item.seriesSeason == season
        // && item.type == "original"
      )
    );
    const groupedEpisodes = episodes
      .filter(
        (item) => item.seriesSeason == season
        //  && item.type != "original"
      )
      .reduce((acc, item) => {
        const userId = item.userId;
        if (!acc[userId]) {
          acc[userId] = [];
        }
        acc[userId].push(item);
        return acc;
      }, {});
    // setOtherSeasonEpisodes(groupedEpisodes);
  }, [season]);
  const toggleSeason = (seasonKey) => {
    setExpandedSeasons((prev) => ({
      ...prev,
      [seasonKey]: !prev[seasonKey],
    }));
  };
  const Episode = ({ data }) => {
    const duration =
      data.videoDuration < 60
        ? Math.floor(data.videoDuration) + "s"
        : Math.floor(data.videoDuration / 60) + "m";
    return (
      <div
        className="flex items-center max-w-full rounded-lg overflow-hidden p-2 hover:bg-[#ffffff1a] cursor-pointer flex-row sm:gap-4 gap-2 sm:m-4 mb-2 sm:mb-0"
        onClick={() => dispatch(setMovie(data))}
      >
        <span className="text-white text-xl hidden sm:block">
          {data.seriesEpisode}
        </span>
        <div className="relative">
          <img
            src={data.imageUrl}
            alt={data.name}
            className={`w-40 h-20 object-cover rounded-xl sm:w-60 sm:h-28  ${
              movie.id === data.id && "opacity-60"
            }`}
          />
          {movie.id === data.id && (
            <PlayCircle
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
              size={40}
            />
          )}
        </div>
        <div className="px-2 w-full sm:px-4">
          <div className="flex items-center justify-between">
            <h3 className="font-bold text-lg sm:text-xl text-white-800">
              {data.name}
            </h3>
            <h3 className="font-bold text-xl text-white-800">
              {isNaN(duration) ? "" : duration}
            </h3>
          </div>
          <p className="text-gray-300 text-sm mt-1">{data.description}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="mx-auto px-4 py-8 w-full max-w-7xl md:px-12">
      {diffSeason.length != 0 && (
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-semibold text-white">Season {season}</h2>
          <select
            className="px-4 py-2 rounded-lg bg-zinc-800 text-white"
            value={season}
            onChange={(e) => setSeason(e.target.value)}
          >
            {diffSeason.map((s, index) => (
              <option key={index} value={s}>
                Season {s}
              </option>
            ))}
          </select>
        </div>
      )}
      {seasonEpisodes?.map((item, index) => {
        return <Episode key={index} data={item} />;
      })}
      {otherSeasonEpisodes.length != 0 && (
        <h2 className="text-2xl font-semibold text-white mt-10">
          Other Season {season}
        </h2>
      )}
      {Object.keys(otherSeasonEpisodes).map((key, index) => (
        <div key={index} className="mt-4">
          <div
            className="flex items-center justify-between p-3 bg-zinc-800 rounded-lg cursor-pointer hover:bg-zinc-700"
            onClick={() => toggleSeason(key)}
          >
            <h3 className="text-xl font-semibold text-white">
              Season by- {otherSeasonEpisodes[key][0]?.userName || "Anonymous"}
            </h3>
            {expandedSeasons[key] ? (
              <ChevronUp size={20} className="text-white" />
            ) : (
              <ChevronDown size={20} className="text-white" />
            )}
          </div>
          {expandedSeasons[key] && (
            <div className="mt-2">
              {otherSeasonEpisodes[key].map((item, index) => (
                <Episode key={index} data={item} />
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
