import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import FAQAccordion from "../components/UI/FAQAccordian";
import mixpanel from "mixpanel-browser";
import { Helmet } from "react-helmet";
const reasons = [
  {
    title: "What is ZingRoll?",
    description:
      "ZingRoll is an AI-powered video publishing platform that delivers dynamic, engaging, and ever-evolving video content to audiences worldwide.",
  },
  {
    title: "How does ZingRoll work?",
    description:
      "ZingRoll curates and publishes AI-generated video content, offering continuous entertainment with fresh and evolving storylines based on trends and audience engagement.",
  },
  {
    title: "Is ZingRoll free to use?",
    description:
      "ZingRoll offers both free and premium plans. Free users can enjoy selected content, while premium users get exclusive access to extended shows and features.",
  },
  {
    title: "What type of content is available on ZingRoll?",
    description:
      "ZingRoll features AI-driven video content across various genres like drama, thriller, sci-fi, romance, and more—delivering non-stop entertainment.",
  },
  {
    title: "Can I create my own videos on ZingRoll?",
    description:
      "Yes! ZingRoll allows users to upload their own AI-generated videos through a profit-sharing model, enabling creators to monetize their content while reaching a wider audience.",
  },
  {
    title: "How is ZingRoll different from other video platforms?",
    description:
      "Unlike traditional streaming platforms, ZingRoll offers constantly evolving video content using AI, ensuring fresh and engaging experiences without waiting for new releases.",
  },
  {
    title: "Can I watch ZingRoll content on different devices?",
    description:
      "Yes! ZingRoll is accessible on smartphones, tablets, and desktops, with plans to expand to smart TVs and other streaming devices.",
  },
  {
    title: "How does AI personalize my content experience?",
    description:
      "ZingRoll’s AI analyzes your preferences, watch history, and engagement to recommend the most relevant and exciting video content tailored just for you.",
  },
  {
    title: "Is ZingRoll available globally?",
    description:
      "Yes, ZingRoll is accessible worldwide, but certain features and content may vary depending on your region.",
  },
  {
    title: "How can I stay updated with ZingRoll’s latest features?",
    description:
      "Follow us on social media, subscribe to our newsletter, and check our website for updates on new AI-generated content and platform enhancements.",
  },
  {
    title: "Who owns the rights to the movie/series uploaded on Zingroll?",
    description: `Everything belongs to the creator! Zingroll is an open and free platform for anyone to launch and distribute their amazing work, and for consumer to watch it for free. Zingroll reserves the right to use the
uploaded content commercially in perpetuity (run ads etc..). Zingroll may reach out to some creators to have exclusive rights later on, but that also follows with additional money negotiated and paid
out to the creator, just as any major streaming site like Netflix would.
However, this is not the case for the vast majority of creators.`,
  },
  {
    title: "How do we contact Zingroll?",
    description: `Please write to our CEO directly: harshit@zingroll.com, or DM us on
our Instagram/X handle @zingroll anytime!`,
  },
  {
    title: "How big is the platform? How many people watch movies here?",
    description: `We're actively growing via organic and social media campaigns, and
will soon reach active viewership in the tens of millions. Search
"Zingroll" on Google videos or TikTok to find out!`,
  },
  {
    title: "Do the movies/series have to be fully Al generated?",
    description: `No! Creators can use Al as much or as little as they want - the goal is to push out the best quality content possible. Al only enables creators to surpass big-budget requirements to create amazing cinematic experiences, and each creator decides how much real vs Al content they'd want to use in their shows. Zingroll enables all such creators to gain massive distribution from day 1 at no cost- given the content
is great- something Netflix won't or YouTube can't.`,
  },
  {
    title: "How do creators earn?",
    description: `Zingroll has the best in-class creator program for Al filmmakers globally - we directly give all proceeds to the creators. This includes a minimum signing amount in some cases (highly in-demand content), and a pay-per-view payment monthly in every case from Day 1. There are many additional incentives as the movies/series start to become
popular.`,
  },
];
export default function Faq() {
  useEffect(() => {
    mixpanel.track("Navigate to Faq Page");
  }, []);
  return (
    <div>
      <Helmet>
        <title>
          Zingroll FAQs – Your Guide to AI-Powered Video Entertainment
        </title>
        <meta
          name="description"
          content="Find answers to your questions about Zingroll, the AI-powered video platform featuring movies, never-ending shows. Explore features, content, and more!"
        />
      </Helmet>
      <Header />
      <section className="mx-auto px-4 py-8 w-full max-w-7xl sm:px-12">
        <h2 className="text-[25px] md:text-[30px] lg:text-[30px] font-bold mb-8">
          Zingroll FAQs – Everything You Need to Know!
        </h2>
        <div className="space-y-4">
          {reasons.map((reason, index) => (
            <FAQAccordion
              key={index}
              question={reason.title}
              answer={reason.description}
            />
          ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}
