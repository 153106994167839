import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { useState, useRef } from "react";

export default function VideoMerger() {
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState("");
  const ffmpegRef = useRef(new FFmpeg());
  const messageRef = useRef(null);
  const fileInputRef = useRef(null);
  const audioInputRef = useRef(null);
  const [videoFiles, setVideoFiles] = useState([]);
  const [mergedVideoUrl, setMergedVideoUrl] = useState(null);
  const [audioFiles, setAudioFiles] = useState([]);
  const [audioPreviewUrls, setAudioPreviewUrls] = useState([]);

  const load = async () => {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    const ffmpeg = ffmpegRef.current;

    ffmpeg.on("log", ({ message }) => {
      messageRef.current.innerHTML = message;
      console.log(message);
    });

    ffmpeg.on("progress", ({ progress: prog, time }) => {
      setProgress(`Processing: ${Math.round(prog * 100)}% (${time}ms)`);
    });

    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
    });
    setLoaded(true);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const videoUrl = URL.createObjectURL(file);
      setVideoFiles((prevFiles) => [
        ...prevFiles,
        { file, name: file.name, preview: videoUrl },
      ]);
      event.target.value = null;
    }
  };

  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAudioFiles((prevFiles) => [...prevFiles, file]);
      setAudioPreviewUrls((prevUrls) => [
        ...prevUrls,
        URL.createObjectURL(file),
      ]);
      event.target.value = null;
    }
  };

  const removeVideo = (index) => {
    setVideoFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const removeAudio = (index) => {
    setAudioFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setAudioPreviewUrls((prevUrls) => prevUrls.filter((_, i) => i !== index));
  };

  const mergeVideos = async () => {
    if (videoFiles.length < 2) {
      messageRef.current.innerHTML = "Please select at least 2 video files.";
      return;
    }

    try {
      const ffmpeg = ffmpegRef.current;

      for (let i = 0; i < videoFiles.length; i++) {
        const fileData = await fetchFile(videoFiles[i].file);
        await ffmpeg.writeFile(`input${i}.mp4`, fileData);
      }

      const fileList = Array.from(
        { length: videoFiles.length },
        (_, i) => `file 'input${i}.mp4'`
      ).join("\n");
      await ffmpeg.writeFile("filelist.txt", fileList);

      let command = ["-f", "concat", "-safe", "0", "-i", "filelist.txt"];

      if (audioFiles.length > 0) {
        for (let i = 0; i < audioFiles.length; i++) {
          const audioData = await fetchFile(audioFiles[i]);
          await ffmpeg.writeFile(`audio${i}.mp3`, audioData);
          command = [...command, "-i", `audio${i}.mp3`];
        }

        let filterComplex = "";

        for (let i = 0; i < audioFiles.length; i++) {
          filterComplex += `[${i + 1}:a]apad[a${i}];`;
        }

        const audioInputs = audioFiles.map((_, i) => `[a${i}]`).join("");
        filterComplex += `${audioInputs}amix=inputs=${audioFiles.length}:dropout_transition=0:normalize=0[aout]`;

        command = [
          ...command,
          "-filter_complex",
          filterComplex,
          "-map",
          "0:v",
          "-map",
          "[aout]",
          "-c:v",
          "copy",
          "-c:a",
          "aac",
          "-b:a",
          "384k",
          "-shortest",
          "output.mp4",
        ];
      } else {
        command = [...command, "-c", "copy", "output.mp4"];
      }

      await ffmpeg.exec(command);

      const data = await ffmpeg.readFile("output.mp4");
      const videoUrl = URL.createObjectURL(
        new Blob([data.buffer], { type: "video/mp4" })
      );
      setMergedVideoUrl(videoUrl);
      messageRef.current.innerHTML = "Video merging completed successfully!";
    } catch (error) {
      messageRef.current.innerHTML = `Error: ${error.message}`;
      console.error(error);
    }
  };

  return (
    <div
      style={{
        fontFamily: "sans-serif",
        padding: "20px",
        maxWidth: "600px",
        margin: "auto",
      }}
    >
      {!loaded ? (
        <button
          onClick={load}
          style={{
            padding: "10px 20px",
            backgroundColor: "#007bff",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Load ffmpeg-core (~31 MB)
        </button>
      ) : (
        <>
          <div style={{ marginBottom: "20px" }}>
            <input
              type="file"
              ref={fileInputRef}
              accept="video/*"
              onChange={handleFileChange}
              style={{ display: "block", marginBottom: "10px" }}
            />
            <input
              type="file"
              ref={audioInputRef}
              accept="audio/*"
              onChange={handleAudioChange}
              style={{ display: "block", marginBottom: "10px" }}
            />
            {audioPreviewUrls.length > 0 && (
              <div style={{ marginBottom: "20px" }}>
                <p>Selected Audio Tracks:</p>
                {audioPreviewUrls.map((url, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <audio src={url} controls style={{ display: "block" }} />
                    <button
                      onClick={() => removeAudio(index)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }}
                    >
                      Delete Audio
                    </button>
                  </div>
                ))}
              </div>
            )}
            <button
              onClick={mergeVideos}
              style={{
                padding: "10px 20px",
                backgroundColor: "#28a745",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Merge Videos
            </button>
          </div>

          {videoFiles.length > 0 && (
            <div style={{ marginBottom: "20px" }}>
              <p>Selected Videos:</p>
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {videoFiles.map((video, index) => (
                  <li
                    key={index}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "5px",
                    }}
                  >
                    <video
                      src={video.preview}
                      controls
                      style={{ height: "100px", marginRight: "10px" }}
                    />
                    <span>{video.name}</span>
                    <button
                      onClick={() => removeVideo(index)}
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "red",
                        color: "white",
                        border: "none",
                        padding: "5px 10px",
                        borderRadius: "5px",
                      }}
                    >
                      Delete
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {mergedVideoUrl && (
            <video
              src={mergedVideoUrl}
              controls
              style={{ maxWidth: "100%", marginBottom: "20px" }}
            />
          )}

          <p ref={messageRef} style={{ color: "red" }}></p>
          <p>{progress}</p>
          <p style={{ fontSize: "0.8em", color: "#888" }}>
            Open Developer Tools (Ctrl+Shift+I) to View Logs
          </p>
        </>
      )}
    </div>
  );
}
