import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../utils/api";
import { LoaderCircle, X } from "lucide-react";

function UserSharedVideo() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await api.post(
          "https://g04y0hryab.execute-api.us-east-2.amazonaws.com/ffmpeg/get-by-id",
          {
            id: id,
          }
        );
        // console.log("res", response.data.data);
        setData(response.data.data);
        setLoading(false);
      } catch (e) {
        console.error("Error fetching video data:", e);
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoaderCircle className="mx-auto animate-spin" size={35} />
      </div>
    );
  }

  if (data == null) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p>Video not found.</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen max-w-4xl m-auto bg-zinc-900">
      {data.s3Url && (
        <video controls className="w-full rounded-lg shadow-md ">
          <source src={data.s3Url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      <button
        onClick={handleButtonClick}
        className="mt-4 bg-white text-black font-bold py-2 px-4 rounded"
      >
        Show Details
      </button>

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-zinc-800 p-6 rounded-lg shadow-lg w-full max-w-2xl relative">
            <h2 className="text-lg font-semibold mb-4">Chat Summary</h2>
            {data.chatSummary ? <p>{data.chatSummary}</p> : <p>No summary</p>}
            <span>
              <X
                onClick={closePopup}
                className="absolute top-3 right-3 text-white cursor-pointer"
                size={25}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserSharedVideo;
