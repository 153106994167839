const FbPixel = (event, data = {}) => {
  if (window.fbq) {
    window.fbq("track", event, data);
    // console.log(`Facebook Pixel Event Tracked: ${event}`, data);
  } else {
    console.error("Facebook Pixel is not loaded");
  }
};

export default FbPixel;
