import {
  setDescription,
  setName,
  setProfile,
} from "../../redux/slice/templateGroups/episodeEdit";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useRef, useState } from "react";
import { ImagePlus, Loader, LoaderCircle, X } from "lucide-react";
import { api } from "../../utils/api";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { toast } from "react-toastify";
import UploadVideo from "../AddNewSeason/uploadVideo";
import Details from "../AddNewSeason/Details";
export default function EditGroupModal({
  modalRef,
  modalRef2,
  season,
  episode,
}) {
  console.log(season, episode);
  const dispatch = useDispatch();
  const { name, description, videoUrl, imageUrl, id } = useSelector(
    (state) => state.episodeEdit
  );
  const { step, series } = useSelector((state) => state.upload);

  const renderSteps = () => {
    if (step == 1) return <UploadVideo />;
    else if (step == 3)
      return (
        <Details
          groupId={id}
          addEpisode={true}
          data={{
            season: season,
            episode: episode,
          }}
        />
      );
    else return <UploadVideo />;
  };

  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-800 p-4 sm:px-8 sm:py-4 rounded-xl shadow-md w-full max-w-5xl h-full relative overflow-scroll"
          onClick={(e) => e.stopPropagation()}
        >
          {/* <X
            onClick={() => modalRef.current.close()}
            size={25}
            className="cursor-pointer absolute top-4 right-4"
          />
          <h2 className="text-xl font-semibold mb-6">Add Episode</h2> */}
          {renderSteps()}
        </div>
      </div>
    </dialog>
  );
}
