import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";

export const addUSerSeries = createAsyncThunk(
  "upload/addSeriesWithExternalData",
  async (payload, { getState }) => {
    const { allOtherGroups } = getState().movie;
    const { user } = getState().auth;
    const series = allOtherGroups.filter((e) => e.userId == user.id);
    return series;
  }
);

export const getUserVideos = createAsyncThunk(
  "upload/getSeriesWithExternalData",
  async (payload, { getState }) => {
    const { user } = getState().auth;
    const { allOtherGroups } = getState().movie;
    const res = await api.post("/videotemplate/get-template-by-categoryID", {
      categoryID: user.id,
      limit: 1000,
    });
    return {
      video: res.data.searchData,
      allOtherGroups,
    };
  }
);
export const getSavedEpisode = createAsyncThunk(
  "upload/getSavedEpisode",
  async (episodeNumber, { getState }) => {
    const { user } = getState().auth;
    const res = await api.post("/videotemplate/get-template-by-categoryID", {
      categoryID: user.id,
      limit: 1000,
    });
    return {
      data: res.data.searchData,
      episodeNumber,
    };
  }
);

const upload = createSlice({
  name: "upload2",
  initialState: {
    step: 1,
    title: "",
    description: "",
    isTrailer: false,
    videoUrl: null,
    poster: null,
    prompt: "",
    seed: "",
    seriesSeason: null,
    seriesEpisode: null,
    trailerProgressBar: false,
    trailerProgressCount: 0,
    selectedSeries: null,
    noOfEpisodes: 0,
    series: [],
    userVideos: [],
    timeline: null,
    noOfEpisodesEnter: null,
    episodeChoson: null,
    noOfEpisodesUploaded: [],
    noOfEpisodesUploadedId: [],
    selectedSeasonNumber: null,
    getEpisodeLoader: false,
    editEpisodeId: null,
  },
  reducers: {
    saveForLater: (state, action) => {
      localStorage.setItem(action.payload, JSON.stringify(state));
    },
    getSavedData: (state, action) => {
      const data = JSON.parse(localStorage.getItem(action.payload));
      if (data !== null) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      } else {
        state.step = 1;
        state.title = "";
        state.description = "";
        state.isTrailer = false;
        state.videoUrl = null;
        state.poster = null;
        state.prompt = "";
        state.seed = "";
        state.seriesSeason = null;
        state.seriesEpisode = null;
        state.trailerProgressBar = false;
        state.trailerProgressCount = 0;
        state.selectedSeries = null;
        state.noOfEpisodes = 0;
        state.userVideos = [];
        state.timeline = null;
        state.noOfEpisodesEnter = null;
        state.episodeChoson = null;
        state.noOfEpisodesUploaded = [];
        state.noOfEpisodesUploadedId = [];
        state.selectedSeasonNumber = null;
      }
    },
    setSteps: (state, action) => {
      state.step = action.payload;
    },
    setVideoFile: (state, action) => {
      state.videoUrl = action.payload.url;
      state.isTrailer = action.payload.isTrailer || false;
      state.step = action.payload.step;
    },
    clearVideo: (state) => {
      state.title = "";
      state.description = "";
      state.poster = null;
      state.videoUrl = null;
      state.step = 1;
      state.prompt = "";
      state.seed = "";
      state.trailerProgressBar = false;
      state.trailerProgressCount = 0;
      state.isTrailer = false;
      state.seriesSeason = null;
      state.seriesEpisode = null;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setPoster: (state, action) => {
      state.poster = action.payload;
    },
    setSeriesSeason: (state, action) => {
      state.seriesSeason = action.payload;
    },
    setClearForm: (state) => {
      state.title = "";
      state.description = "";
      state.poster = null;
      state.prompt = "";
      state.videoUrl = null;
      state.seed = "";
      state.editEpisodeId = null;
    },
    setSeriesEpisode: (state, action) => {
      state.seriesEpisode = action.payload;
    },
    clearPoster: (state) => {
      state.poster = null;
    },
    addSeries: (state, action) => {
      state.series = [...state.series, action.payload];
    },
    setTimeline: (state, action) => {
      state.timeline = action.payload;
    },
    editSeries(state, action) {
      const { id, data } = action.payload;
      state.series = state.series.map((item) =>
        item.id === id ? { ...item, ...data } : { ...item }
      );
    },
    addSelectedSeries: (state, action) => {
      state.selectedSeries = action.payload;
      const num = state.userVideos.filter(
        (e) => e.templateGroupID == action.payload
      );
      state.noOfEpisodes = num.length;
    },
    addTrailerProgressCount: (state, action) => {
      state.trailerProgressCount = action.payload;
    },
    addTrailerProgressBar: (state, action) => {
      state.trailerProgressBar = action.payload;
    },
    setPrompt: (state, action) => {
      state.prompt = action.payload;
    },
    seetSeed: (state, action) => {
      state.seed = action.payload;
    },
    setNoOfEpisode: (state, action) => {
      state.noOfEpisodesEnter = action.payload;
    },
    setNoOfEpisodesUploaded: (state, action) => {
      state.noOfEpisodesUploaded = [
        ...state.noOfEpisodesUploaded,
        action.payload,
      ];
    },
    setNoOfEpisodesUploadedId: (state, action) => {
      state.noOfEpisodesUploadedId = [
        ...state.noOfEpisodesUploadedId,
        action.payload,
      ];
    },
    setEpisodeChoson: (state, action) => {
      state.episodeChoson = action.payload;
    },
    setSelectedSeasonNumber: (state, action) => {
      state.selectedSeasonNumber = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addUSerSeries.fulfilled, (state, action) => {
      state.series = action.payload;
    });
    builder.addCase(getUserVideos.fulfilled, (state, action) => {
      state.userVideos = action.payload.video;

      const series = JSON.parse(JSON.stringify(state.series));
      // console.log("series", series);
      const other = [
        ...new Set(
          action.payload.video
            .filter((e) => series.every((s) => s.id !== e.templateGroupID))
            .map((e) => e.templateGroupID)
        ),
      ];
      // console.log("other", other);

      const a = action.payload.allOtherGroups.filter((e) =>
        other.includes(e.id)
      );
      // console.log("aa", a);
      state.series = [...a, ...state.series];
    });

    builder.addCase(getSavedEpisode.pending, (state, action) => {
      state.getEpisodeLoader = true;
    });
    builder.addCase(getSavedEpisode.fulfilled, (state, action) => {
      state.getEpisodeLoader = false;
      const idx = state.noOfEpisodesUploaded.findIndex(
        (item) => item == action?.payload?.episodeNumber
      );
      const data = action.payload.data.filter(
        (item) => item.id == state.noOfEpisodesUploadedId[idx]
      );
      const newdata = data[0];
      state.editEpisodeId = newdata.id;
      state.title = newdata.name;
      state.description = newdata.description;
      state.poster = newdata.imageUrl;
      state.videoUrl = newdata.videoUrl;
      state.prompt = newdata.prompt;
      state.seed = newdata.seed;
    });
  },
});

export const {
  setSteps,
  setVideoFile,
  clearVideo,
  setPoster,
  setTimeline,
  clearPoster,
  setTitle,
  editSeries,
  setDescription,
  setClearForm,
  addSeries,
  addSelectedSeries,
  addTrailerProgressCount,
  addTrailerProgressBar,
  setPrompt,
  seetSeed,
  setSeriesSeason,
  setSeriesEpisode,
  setNoOfEpisode,
  setNoOfEpisodesUploaded,
  setNoOfEpisodesUploadedId,
  setEpisodeChoson,
  setSelectedSeasonNumber,
  saveForLater,
  getSavedData,
} = upload.actions;
export default upload.reducer;
