import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Pen, Eye, List, Image, Clapperboard, Video } from "lucide-react";
import Loader from "../components/UI/Loader";
import ViewGroupModal from "../components/Myvideos/groupView";
import EditCategoryModal from "../components/Myvideos/groupEdit";
import { setTemplate } from "../redux/slice/templateGroups/groupEdit";
import { setTemplate as setTemplateForView } from "../redux/slice/templateGroups/groupView";
import { fetchEpisodes } from "../redux/slice/templateGroups/episodeList";
export default function MyVideos({ isTraier }) {
  const editModalRef = useRef();
  const viewModalRef = useRef();
  const dispatch = useDispatch();
  const listModalRef = useRef();
  const editGroup = (data) => {
    console.log(data);
    dispatch(setTemplate(data));
    editModalRef.current.showModal();
  };

  const viewGroup = (data) => {
    console.log(data);
    dispatch(setTemplateForView(data));
    viewModalRef.current.showModal();
  };

  const episodeList = (id, name, categoryID, imageUrl) => {
    dispatch(
      fetchEpisodes({ templateGroupID: id, name, categoryID, imageUrl })
    );
    listModalRef.current.showModal();
  };

  return (
    <section className="mx-auto w-full max-w-7xl sm:px-12 ">
      <div className="flex gap-5 items-center"></div>
      {isTraier?.length != 0 ? (
        <div className="">
          {isTraier?.map((item, index) => {
            return (
              <SeriesCard
                key={item.id}
                trailerShown={true}
                {...item}
                editGroup={editGroup}
                viewGroup={viewGroup}
                episodeList={episodeList}
              />
            );
          })}
        </div>
      ) : (
        <div className="flex items-center justify-center h-[50vh]">
          <h1 className="text-2xl font-bold text-gray-300">No Trailer Found</h1>
        </div>
      )}
      <EditCategoryModal modalRef={editModalRef} />
      <ViewGroupModal modalRef={viewModalRef} />
    </section>
  );

  function SeriesCard(props) {
    const {
      id,
      status,
      prompt,
      description,
      name,
      templateGroupProfile,
      type,
      categoryLabel,
      categoryID,
      viewGroup,
      editGroup,
      trailer,
      userId,
      episodeList,
      trailerShown,
      trailerProgressBar,
      trailerProgressNumber,
    } = props;
    const imageRef = useRef();
    const [imageLoading, setImageLoading] = useState(true);
    const { user } = useSelector((state) => state.auth);
    return (
      <div
        className={`flex rounded-md transition-colors duration-200 flex-col relative hover:cursor-pointer`}
      >
        <div
          className="rounded-md relative overflow-hidden"
          onClick={() => {
            !trailerShown &&
              // viewGroup({
              //   id,
              //   status,
              //   prompt,
              //   description,
              //   name,
              //   templateGroupProfile,
              //   type,
              //   categoryLabel,
              //   categoryID,
              // });

              episodeList(id, name, categoryID, templateGroupProfile, userId);
          }}
        >
          {!trailerShown ? (
            templateGroupProfile ? (
              <img
                src={templateGroupProfile}
                ref={imageRef}
                onLoad={(e) => setImageLoading(false)}
                className={`object-cover h-full w-full ${
                  imageLoading ? "animate-pulse" : ""
                } bg-gray-200`}
              />
            ) : (
              <div className="flex flex-col items-center justify-center h-full w-full bg-zinc-800">
                <Image size={50} />
                <div className="mt-1">No Image Found</div>
              </div>
            )
          ) : (
            <video
              src={trailer.videoUrl}
              controls
              poster={trailer.imageUrl}
              className="size-full rounded-md object-cover"
            />
          )}
          {/* {trailer && !trailerShown ? (
          <span className="absolute bg-white border rounded-full p-2 opacity-95 top-2 right-2">
            🎬
          </span>
        ) : null} */}
          {status == 0 ? (
            <span className="absolute text-sm bg-zinc-800 rounded-full p-2 opacity-95 top-2 right-2">
              Pending
            </span>
          ) : status == 1 ? (
            <span className="absolute text-sm rounded-full bg-green-700 p-2 opacity-95 top-2 right-2">
              Approved
            </span>
          ) : (
            <span className="absolute text-sm rounded-full bg-red-500 p-2 opacity-95 top-2 right-2">
              Rejected
            </span>
          )}
        </div>
        <div
          className={`flex flex-col ${
            status ? "text-gray-600" : "text-gray-400"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-end mt-2">
            {/* edit button */}
            {!trailerShown && userId == user.id && (
              <button
                className="items-center px-4 py-2 hover:bg-zinc-900"
                onClick={() => {
                  // Handle edit action
                  editGroup({
                    isTrailer: false,
                    id,
                    status,
                    prompt,
                    description,
                    name,
                    templateGroupProfile,
                    type,
                    categoryLabel,
                    categoryID,
                  });
                }}
              >
                <Pen size={20} />
              </button>
            )}
            {trailer && trailerShown && (
              <button
                className="items-center px-4 py-2 hover:bg-zinc-900"
                onClick={() => {
                  editGroup({
                    isTrailer: true,
                    id,
                    status,
                    prompt,
                    description,
                    name,
                    templateGroupProfile,
                    type,
                    trailer,
                    categoryLabel,
                    categoryID,
                    trailerProgressBar,
                    trailerProgressNumber,
                  });
                }}
              >
                <Pen size={20} />
              </button>
            )}
            {/* view episodes button */}
            {!trailerShown && (
              <button
                className=" items-center px-4 py-2 hover:bg-zinc-900"
                onClick={() => {
                  episodeList(
                    id,
                    name,
                    categoryID,
                    templateGroupProfile,
                    userId
                  );
                }}
              >
                <List size={25} />
              </button>
            )}
          </div>
          <div className="flex flex-col pr-4 grow">
            <span className="grow text-xl font-bold ">
              {!trailerShown ? name : trailer.name}
            </span>
            {/* <span className="text-sm ">
            {!trailerShown ? description : trailer.description}
          </span> */}
          </div>
        </div>
      </div>
    );
  }
}
