import React, { useState, useEffect } from "react";
import { X, ChevronDown, ChevronUp, Play, Eye } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSteps, setTimeline } from "../../redux/slice/Upload";
import { toast } from "react-toastify";
export default function EditGroupModal({ modalRef, modalRef2, season }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { episodes, movie } = useSelector((state) => state.movie);
  const [selectedSeasonNumber, setSelectedSeasonNumber] = useState(season);
  const [otherSeasonEpisodes, setOtherSeasonEpisodes] = useState({});
  const [selectedVersions, setSelectedVersions] = useState({});
  const [expandedSeasons, setExpandedSeasons] = useState({});
  const [selectedEpisode, setSelectedEpisode] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const totalSeasons = Object.keys(otherSeasonEpisodes).length;
  const availableSeasons = Array.from(
    { length: totalSeasons + 1 },
    (_, i) => i + 1
  );
  useEffect(() => {
    setSelectedSeasonNumber(season);
  }, [season]);
  useEffect(() => {
    if (episodes.length === 0) {
      dispatch(setSteps(2));
    }
    if (selectedSeasonNumber == null || selectedSeasonNumber == 1)
      setSelectedSeasonNumber(totalSeasons + 1);
    if (
      Object.keys(otherSeasonEpisodes).length > 0 &&
      Object.keys(selectedVersions).length === 0
    ) {
      const sortedSeasons = Object.keys(otherSeasonEpisodes).sort(
        (a, b) => Number(a) - Number(b)
      );
      const initialSelections = {};
      sortedSeasons.forEach((season) => {
        const firstUserData = Object.entries(otherSeasonEpisodes[season])[0];
        if (firstUserData) {
          const [userId, userEpisodes] = firstUserData;
          initialSelections[season] = { userId, episodes: userEpisodes };
        }
      });
      setSelectedVersions(initialSelections);
    }
  }, [otherSeasonEpisodes]);

  useEffect(() => {
    // Group episodes by season first, then by user
    const groupedEpisodes = episodes.reduce((acc, item) => {
      const season = item.seriesSeason;
      if (!acc[season]) {
        acc[season] = {};
      }
      if (!acc[season][item.userId]) {
        acc[season][item.userId] = [];
      }
      acc[season][item.userId].push(item);
      return acc;
    }, {});
    setOtherSeasonEpisodes(groupedEpisodes);
  }, [episodes]);

  const handleVersionSelect = (season, userId, episodes) => {
    setSelectedVersions((prev) => {
      // If clicking the same version again, remove it
      if (prev[season]?.userId === userId) {
        const { [season]: removed, ...rest } = prev;
        return rest;
      }
      // Replace any existing version for this season with the new one
      return {
        ...prev,
        [season]: { userId, episodes },
      };
    });
  };

  const allEpisodes = Object.entries(selectedVersions).flatMap(
    ([season, data]) => data.episodes.map((ep) => ({ ...ep, season }))
  );

  const toggleSeason = (season) => {
    setExpandedSeasons((prev) => ({
      ...prev,
      [season]: !prev[season],
    }));
  };

  // Group episodes by season
  const groupedEpisodes = allEpisodes.reduce((acc, episode) => {
    if (!acc[episode.season]) {
      acc[episode.season] = [];
    }
    acc[episode.season].push(episode);
    return acc;
  }, {});

  // Add this function to filter seasons
  const getFilteredSeasons = () => {
    return Object.keys(otherSeasonEpisodes)
      .filter((season) => Number(season) < selectedSeasonNumber)
      .sort((a, b) => Number(a) - Number(b));
  };

  // Modify the allSeasonsSelected check
  const allSeasonsSelected =
    getFilteredSeasons().length > 0 &&
    getFilteredSeasons().every((season) => selectedVersions[season]);

  const handleContinue = () => {
    const selectedSeasonData = Object.entries(selectedVersions)
      .sort(([seasonA], [seasonB]) => Number(seasonA) - Number(seasonB))
      .map(([season, data]) => ({
        season,
        userId: data.userId,
        episodes: data.episodes.map((ep) => ep.id),
      }));

    console.log(
      "Selected Seasons:",
      selectedSeasonData.slice(0, selectedSeasonNumber - 1)
    );
    toast.success("Timeline Updated Successfully");
    modalRef.current.close();
  };

  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4 overflow-auto"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-900 p-8 h-[90%] rounded-lg shadow-lg max-w-5xl w-full relative overflow-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="flex justify-between border-b-2 border-zinc-700">
            <h1 className="text-xl font-bold mb-4">Edit TimeLien</h1>
            <X
              onClick={() => modalRef.current.close()}
              size={25}
              className="cursor-pointer"
            />
          </div>
          <div className="p-4 m-auto flex justify-center">
            <div className="max-w-xs">
              <label className="block text-sm font-medium mb-2">
                Select Season to Upload
              </label>
              <select
                value={selectedSeasonNumber}
                onChange={(e) =>
                  setSelectedSeasonNumber(Number(e.target.value))
                }
                className="w-full bg-zinc-800 border border-zinc-700 rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                {availableSeasons.map((season) => (
                  <option key={season} value={season}>
                    Season {season}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="overflow-y-auto px-4 flex items-center justify-center mb-20 md:mb-0">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:hidden gap-6">
              {getFilteredSeasons().map((season) => (
                <div key={season} className="w-full">
                  <h2 className="text-xl font-bold mb-4 sticky left-0">
                    Season {season}
                  </h2>
                  <div className="flex flex-row md:flex-col gap-4">
                    {Object.entries(otherSeasonEpisodes[season]).map(
                      ([userId, userEpisodes]) => (
                        <div
                          key={userId}
                          onClick={() =>
                            handleVersionSelect(season, userId, userEpisodes)
                          }
                          className={`w-full min-w-[200px] p-4 border rounded-lg shadow-md flex flex-col items-center justify-center cursor-pointer transition-colors relative min-h-[100px] ${
                            selectedVersions[season]?.userId === userId
                              ? "bg-blue-700 border-blue-500"
                              : "hover:bg-zinc-800"
                          }`}
                        >
                          {userId && (
                            <h3 className="text-base md:text-lg font-semibold mb-2 text-center">
                              Version by {userId.slice(0, 8)}
                            </h3>
                          )}
                          <p className="text-gray-400 text-sm md:text-base">
                            {userEpisodes.length} Episodes
                          </p>
                          <Eye
                            className="absolute bottom-2 right-2"
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowModal(true);
                              setSelectedVersion({
                                season,
                                userId,
                                episodes: userEpisodes,
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>

            <div className="hidden lg:flex flex-row gap-6 overflow-x-auto pb-6">
              {getFilteredSeasons().map((season) => (
                <div key={season} className="min-w-[300px] flex-shrink-0">
                  <h2 className="text-xl font-bold mb-4 sticky left-0">
                    Season {season}
                  </h2>
                  <div className="flex flex-col gap-4">
                    {Object.entries(otherSeasonEpisodes[season]).map(
                      ([userId, userEpisodes]) => (
                        <div
                          key={userId}
                          onClick={() =>
                            handleVersionSelect(season, userId, userEpisodes)
                          }
                          className={`w-full p-4 border rounded-lg shadow-md flex flex-col items-center justify-center cursor-pointer transition-colors relative min-h-[100px] ${
                            selectedVersions[season]?.userId === userId
                              ? "bg-blue-700 border-blue-500"
                              : "hover:bg-zinc-800"
                          }`}
                        >
                          {userId && (
                            <h3 className="text-base md:text-lg font-semibold mb-2 text-center">
                              Version by {userId.slice(0, 8)}
                            </h3>
                          )}
                          <p className="text-gray-400 text-sm md:text-base">
                            {userEpisodes.length} Episodes
                          </p>
                          <Eye
                            className="absolute bottom-2 right-2"
                            size={20}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowModal(true);
                              setSelectedVersion({
                                season,
                                userId,
                                episodes: userEpisodes,
                              });
                            }}
                          />
                        </div>
                      )
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 fixed bottom-0 left-0 right-0 bg-zinc-900 border-t border-zinc-700">
            <div className="max-w-xl mx-auto">
              <button
                onClick={handleContinue}
                className={`w-full py-3 px-4 ${"bg-blue-600 hover:bg-blue-700"} text-white font-semibold rounded-lg transition-colors`}
              >
                {/* Create Season {selectedSeasonNumber} */}
                Save
              </button>
            </div>
          </div>

          {showModal && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-2 z-50">
              <div className="bg-zinc-900 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto m-2">
                <div className="p-4 md:p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl md:text-2xl font-bold">
                      Season {selectedVersion.season}
                    </h2>
                    <X
                      onClick={() => {
                        setShowModal(false);
                        setSelectedVersion(null);
                      }}
                      size={24}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="space-y-3">
                    {selectedVersion.episodes
                      .sort(
                        (a, b) =>
                          Number(a.seriesEpisode) - Number(b.seriesEpisode)
                      )
                      .map((episode) => (
                        <div
                          key={episode.id}
                          onClick={() => setSelectedEpisode(episode)}
                          className="flex items-center gap-3 h-24 p-2 md:p-3 border rounded-lg hover:bg-zinc-800 cursor-pointer"
                        >
                          <div className="w-16 md:w-20 h-full rounded-lg overflow-hidden">
                            <img
                              src={episode.imageUrl}
                              alt={episode.name}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <h4 className="font-semibold text-sm md:text-base truncate">
                              {episode.name}
                            </h4>
                            <p className="text-gray-400 text-sm">
                              Episode {episode.seriesEpisode}
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedEpisode && (
            <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center p-2 z-50">
              <div className="bg-zinc-900 rounded-lg w-full max-w-4xl max-h-[90vh] overflow-y-auto m-2">
                <div className="p-4 md:p-6">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-bold">
                      {selectedEpisode.name}
                    </h2>
                    <X
                      onClick={() => setSelectedEpisode(null)}
                      size={24}
                      className="cursor-pointer"
                    />
                  </div>

                  <div className="aspect-video bg-black mb-4 rounded-lg overflow-hidden">
                    <video
                      src={selectedEpisode.videoUrl}
                      controls
                      className="w-full h-full"
                      poster={selectedEpisode.imageUrl}
                    />
                  </div>

                  <div className="space-y-4">
                    <div>
                      <h3 className="text-lg font-semibold mb-2">
                        Episode Details
                      </h3>
                      <p className="text-gray-400">
                        Season {selectedEpisode.season} Episode{" "}
                        {selectedEpisode.seriesEpisode}
                      </p>
                      <p className="text-gray-300 mt-2">
                        {selectedEpisode.description}
                      </p>
                    </div>

                    <div>
                      <h3 className="text-lg font-semibold mb-2">
                        Additional Info
                      </h3>
                      <p className="text-gray-400">
                        Views: {selectedEpisode.views}
                      </p>
                      <p className="text-gray-400">
                        Likes: {selectedEpisode.likes}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </dialog>
  );
}
