import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import { api } from "../utils/api";
import { LoaderCircle } from "lucide-react";
import { toast } from "react-toastify";
function ShakingButton() {
  const [isShaking, setIsShaking] = useState(false);

  useEffect(() => {
    const shakeInterval = setInterval(() => {
      setIsShaking(true);
      setTimeout(() => setIsShaking(false), 820);
    }, 1820);
    return () => clearInterval(shakeInterval);
  }, []);

  return (
    <button
      className={`bg-white text-black mt-5 font-semibold md:text-xl text-base self-stretch w-full py-5 rounded-full ${
        isShaking ? "animate-shake" : ""
      }`}
    >
      <a
        href="https://apps.apple.com/us/app/zingroll-binge-a-show-forever/id6466747851"
        target="_blank"
        // onClick={storeAnalytics}/
      >
        See who likes you
      </a>
    </button>
  );
}

function UserCounter() {
  const [count, setCount] = useState(Math.floor(Math.random() * 200) + 100);
  const [phase, setPhase] = useState("initial");

  useEffect(() => {
    const interval = setInterval(() => {
      let increment;
      if (phase === "initial") {
        increment = Math.random() < 0.4 ? -1 : Math.floor(Math.random() * 3);
      } else {
        increment = Math.floor(Math.random() * 3) + 1;
      }

      setCount((prevCount) => prevCount + increment);
    }, 1000);

    const phaseTimeout = setTimeout(() => {
      setPhase("incremental");
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(phaseTimeout);
    };
  }, [phase]);

  return (
    <div className="w-full max-w-3xl flex flex-col">
      <p className="text-center font-bold">
        👇{count} people just tapped the button👇
      </p>
      <ShakingButton />
      <div className="self-center flex gap-2 mt-2  text-[#00000086] text-xs ">
        <Link className="font-bold text-white" to="/terms">
          Terms
        </Link>
        <Link className="font-bold text-white" to="/privacy">
          Privacy
        </Link>
      </div>
    </div>
  );
}

function InputForm({
  id,
  userid,
  sentFrom,
  name,
  message_type,
  placeholder,
  sent_link,
}) {
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const sendMessage = async () => {
    try {
      const response = await api.post(
        "https://g04y0hryab.execute-api.us-east-2.amazonaws.com/messages/send",
        {
          userId: id,
          message: message,
        }
      );
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  async function formSubmit(e) {
    e.preventDefault();
    setLoading(true);

    console.log("sending message now");
    if (!message) return;
    try {
      await sendMessage();
      setLoading(false);
      setMessage("");
      toast.success("message sent successfully");
    } catch (err) {
      alert("could send message to the user");
      setLoading(false);
      return;
    }
  }

  return (
    <form
      onSubmit={formSubmit}
      className="flex flex-col items-center mt-8 w-full max-w-3xl"
    >
      <div className="relative rounded-3xl overflow-hidden w-full flex flex-col ">
        <div className="bg-white px-6 py-4 text-center flex gap-2 text-black">
          <div className="flex flex-col ">
            <p className="text-start text-sm">@{name}</p>
            {/* <p className="font-bold">{message_type}</p> */}
          </div>
        </div>
        <textarea
          ref={inputRef}
          placeholder={placeholder}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          className="bg-[#ffffff9c] resize-none outline-none text-xl p-4 text-start h-[150px] placeholder:text-[#000000ab] text-[#000000ab] placeholder:font-bold md:placeholder:text-xl"
        ></textarea>
      </div>
      {/* <span className="text-sm mt-2">🔒 Instagram secret matching</span> */}
      <span className="text-sm mt-2">Zingroll.com</span>
      <button
        type="submit"
        disabled={!Boolean(message)}
        className="bg-[#dadada] md:text-xl text-base flex items-center justify-center px-auto gap-2 text-black mt-10 font-semibold self-stretch py-4 rounded-full"
      >
        {loading ? <LoaderCircle className="mx-auto animate-spin" /> : "Send"}
      </button>
    </form>
  );
}

function NGL({
  color1,
  color2,
  message_type,
  placeholder,
  sentFrom,
  sent_link,
}) {
  const { id: username } = useParams();
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const response = await api.post(
        "https://r78rtf5t5e.execute-api.us-east-2.amazonaws.com/dev/user/user-details-by-id",
        {
          userID: username,
        }
      );
      setName(response.data.user.firstName || response.data.user.lastName);
    } catch (error) {
      console.log(error);
      if (error.response.status == 404) {
        setName(null);
        toast.error("user not found");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <main
      style={{
        background: `linear-gradient(to bottom right, ${color1}, ${color2})`,
      }}
      className="h-screen flex flex-col items-center justify-center  px-7"
    >
      {loading ? (
        <LoaderCircle className="mx-auto animate-spin" size={35} />
      ) : name == null ? (
        <div>User not found</div>
      ) : (
        <>
          <InputForm
            id={username}
            // userid={user}
            sentFrom={sentFrom}
            name={name}
            message_type={message_type}
            placeholder={placeholder}
            sent_link={sent_link}
          />
          <div className="mx-auto mt-16">
            <UserCounter />
          </div>
        </>
      )}
    </main>
  );
}

export default NGL;
