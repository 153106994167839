import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import UploadVideo from "../components/AddNewSeason/uploadVideo";
import Details from "../components/AddNewSeason/Details";
import { useSelector, useDispatch } from "react-redux";
import TimeLine from "../components/AddNewSeason/tileLine";
import { getSavedData } from "../redux/slice/Upload";
function AddNewSeason() {
  const { id } = useParams();
  const { state } = useLocation();
  const { data } = state || {};
  const { step, series } = useSelector((state) => state.upload);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSavedData(id));
  }, []);

  const renderSteps = () => {
    if (step == 1) return <TimeLine data={data} />;
    else if (step == 2) return <UploadVideo data={data} />;
    else if (step == 3) return <Details groupId={id} data={data} />;
    else return <UploadVideo />;
  };

  return (
    <div className="flex items-center justify-center h-screen sm:m-4 overflow-hidden">
      <div className="bg-zinc-800 p-4 sm:px-8 sm:py-4 rounded-xl shadow-md w-full max-w-5xl h-full relative overflow-scroll">
        {renderSteps()}
      </div>
    </div>
  );
}

export default AddNewSeason;
