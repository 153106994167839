import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearVideo,
  setTitle,
  setDescription,
  setPoster,
  addSeries,
  addSelectedSeries,
  editSeries,
  setVideoFile,
  addTrailerProgressBar,
  addTrailerProgressCount,
  setPrompt,
  seetSeed,
  setSeriesSeason,
  setSeriesEpisode,
  setSteps,
  setNoOfEpisodesUploaded,
  setClearForm,
  setNoOfEpisodesUploadedId,
} from "../../redux/slice/Upload";
import { Copy, Plus, X, LoaderCircle, Trash } from "lucide-react";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import mixpanel from "mixpanel-browser";

export default function Details({ groupId, addEpisode }) {
  const {
    videoUrl,
    title,
    description,
    poster,
    series,
    prompt,
    timeline,
    episodeChoson,
    seed,
    selectedSeries,
    noOfEpisodes,
    seriesSeason,
    noOfEpisodesEnter,
    seriesEpisode,
    isTrailer,
    trailerProgressBar,
    trailerProgressCount,
    file,
    editEpisodeId,
    getEpisodeLoader,
  } = useSelector((state) => state.upload);
  const a = useSelector((state) => state.upload);
  console.log(a);
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [group, setGroup] = useState({
    name: "",
    description: "",
    category: "",
    categoryId: "",
    image: "",
  });
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lodingTnail, setLoadingTnail] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const canvasRef = useRef(null);
  const ffmpegRef = useRef(new FFmpeg());
  const [frames, setFrames] = useState([]);
  const [src2, setSrc2] = useState(null);
  const [crop2, setCrop2] = useState(null);
  const imgRef2 = useRef(null);
  const canvasRef2 = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const load = async () => {
    const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
    const ffmpeg = ffmpegRef.current;
    try {
      await ffmpeg.load({
        coreURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.js`,
          "text/javascript"
        ),
        wasmURL: await toBlobURL(
          `${baseURL}/ffmpeg-core.wasm`,
          "application/wasm"
        ),
      });
      console.log("loading");
      extractFrames(videoUrl);
      console.log("done");
    } catch (error) {
      console.error("Error loading FFmpeg:", error);
    }
  };

  useEffect(() => {
    load();
  }, [videoUrl]);

  const handleFileChangeNew = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    // dispatch(
    //   setVideoFile({
    //     url: "https://static-content-for-text.s3.amazonaws.com/mergedVideos/413d9477-b312-4678-b452-54f0c63a6917-merged.mp4",
    //     name: "ademo name",
    //     step: 3,
    //   })
    // );
    try {
      setUploading(true);
      // 1️⃣ Request a pre-signed URL from backend
      const response = await fetch("http://localhost:5432/s3", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fileName: file.name, fileType: file.type }),
      });

      const { uploadUrl, url } = await response.json();
      // console.log(uploadUrl, url);
      // 2️⃣ Upload video directly to S3 using the pre-signed URL
      try {
        await uploadToS3(file, uploadUrl, url);
      } catch (error) {
        console.error(error);
        alert("Upload failed.");
      }
      setUploading(false);

      alert("Upload successful!");
    } catch (error) {
      console.error("Upload failed", error);
    }
  };

  const uploadToS3 = async (file, uploadUrl, url) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", uploadUrl, true);
      xhr.setRequestHeader("Content-Type", file.type);

      // Track Upload Progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percent = Math.round((event.loaded / event.total) * 100);
          setProgress(percent); // Update UI with progress
        }
      };

      // On Success
      xhr.onload = () => {
        if (xhr.status === 200) {
          setUploading(false);
          dispatch(
            setVideoFile({
              url: url,
              name: file.name,
              step: 3,
            })
          );
        } else {
          reject("Upload failed.");
        }
      };

      // On Error
      xhr.onerror = () => reject("Upload error.");

      xhr.send(file); // Send file to S3
    });
  };

  const handleDrop = async (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      try {
        setUploading(true);
        // 1️⃣ Request a pre-signed URL from backend
        const response = await fetch("http://localhost:5432/s3", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ fileName: file.name, fileType: file.type }),
        });

        const { uploadUrl, url } = await response.json();
        // console.log(uploadUrl, url);
        // 2️⃣ Upload video directly to S3 using the pre-signed URL
        try {
          await uploadToS3(file, uploadUrl, url);
        } catch (error) {
          console.error(error);
          alert("Upload failed.");
        }

        alert("Upload successful!");
      } catch (error) {
        console.error("Upload failed", error);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const extractFrames = async (videoUrl) => {
    setLoadingTnail(true);
    const ffmpeg = ffmpegRef.current;
    try {
      const videoURL = videoUrl;

      const videoData = await fetchFile(videoURL);

      await ffmpeg.writeFile("input.mp4", videoData);

      // Verify file exists
      const files = await ffmpeg.listDir("/");

      // Extract frames at specific timestamps
      const timestamps = ["00:00:01", "00:00:03"]; // Extract frames at 1s and 2s
      const outputFilenames = ["frame1.jpg", "frame2.jpg"];

      for (let i = 0; i < timestamps.length; i++) {
        await ffmpeg.exec([
          "-i",
          "input.mp4",
          "-ss",
          timestamps[i],
          "-frames:v",
          "1",
          outputFilenames[i],
        ]);
      }
      // Read extracted frames and convert them to blobs
      const extractedFrames = await Promise.all(
        outputFilenames.map(async (filename) => {
          const frameData = await ffmpeg.readFile(filename);
          const blob = new Blob([frameData], { type: "image/jpeg" }); // Remove .buffer
          return URL.createObjectURL(blob);
        })
      );

      setFrames(extractedFrames);
    } catch (error) {
      console.error("Error extracting frames:", error);
    }
    setLoadingTnail(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!videoUrl) {
      toast.error("Please upload a Show");
      return;
    }
    if (!poster) {
      toast.error("Please upload a Thumbnail");
      return;
    }
    setLoading(true);
    if (editEpisodeId == null) {
      const res = await api.post("/videotemplate/store-video-template-data", {
        videoUrl: videoUrl,
        name: title,
        description: description,
        categoryID: user.id,
        templateGroupID: groupId,
        episodNumber: episodeChoson,
        timeStampFace: [],
        timeStampAudio: [],
        speakers: [],
        UserDataWithTime: [],
        status: 0,
        type: "user-video",
      });

      const update = await api.post(
        "/videotemplate/update-video-template-data",
        {
          id: res.data.userVideoDurationData.id,
          videoTemplateData: {
            imageUrl: poster,
            isAprove: 0,
            views: 0,
            likes: 0,
            dislikes: 0,
            prompt: prompt,
            seed: seed,
            // seriesSeason: seriesSeason,
            totalNoOfEpisodes: noOfEpisodesEnter,
            seriesSeason: timeline?.length + 1,
            seriesEpisode: seriesEpisode,
            userId: user.id,
            userName: user?.userName,
            timeLine: timeline,
          },
        }
      );
      // console.log(update);
      dispatch(setNoOfEpisodesUploadedId(res.data.userVideoDurationData.id));
      toast.success("Date saved!");
      dispatch(setNoOfEpisodesUploaded(episodeChoson));
      mixpanel.track("user uploaded a series", { url: videoUrl });
    } else {
      const update = await api.post(
        "/videotemplate/update-video-template-data",
        {
          id: editEpisodeId,
          videoTemplateData: {
            imageUrl: poster,
            prompt: prompt,
            seed: seed,
            name: title,
            description: description,
            videoUrl: videoUrl,
          },
        }
      );
      toast.success("Date Updated!");
    }
    setLoading(false);
    dispatch(clearVideo());
    dispatch(setSteps(2));
    dispatch(setClearForm());
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    setCrop(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };

  const onImageLoad2 = (e) => {
    const { width, height } = e.currentTarget;
    setCrop2(
      centerCrop(
        makeAspectCrop(
          {
            unit: "px",
            width: width, // Set width to full (video or image width)
            height: (width * 9) / 16, // Calculate height based on 16:9 aspect ratio
          },
          16 / 9, // Maintain 16:9 aspect ratio
          width,
          height
        ),
        width,
        height
      )
    );
  };
  const handleCrop = async (type) => {
    if (!completedCrop || !canvasRef.current || !imgRef.current) {
      return;
    }
    setSrc(null);
    const image = imgRef.current;
    const canvas = canvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      async (blob) => {
        if (blob) {
          const file = new File([blob], "cropped-image.jpg", {
            type: "image/jpeg",
          });
          setImageLoading(true);
          try {
            const response = await fetch("http://localhost:5432/s3", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                fileName: file.name,
                fileType: file.type,
              }),
            });

            const { uploadUrl, url } = await response.json();
            // console.log(uploadUrl, url);
            // 2️⃣ Upload video directly to S3 using the pre-signed URL
            try {
              const response = await fetch(uploadUrl, {
                method: "PUT",
                headers: { "Content-Type": file.type },
                body: file,
              });
            } catch (error) {
              console.error(error);
              alert("Upload failed.");
            }
            console.log(url);
            if (type) {
              setGroup({ ...group, image: url });
            } else {
              dispatch(setPoster(url));
            }
          } catch (error) {
            toast.error("error uploading image");
            console.log(error);
          } finally {
            setImageLoading(false);
          }
        }
      },
      "image/jpeg",
      1
    );
  };
  const handleReset = () => {
    // dispatch(clearVideo());
    dispatch(setSteps(2));
    dispatch(setClearForm());
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(videoUrl);
    toast.success("Link copied to clipboard!");
  };

  const handleFileChange = async (e) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("result,", reader.result);
        setSrc(reader.result); // Set the image source for cropping
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("Please select an image");
    }
  };
  const handleFileChange2 = async (e) => {
    if (e.target.files) {
      const reader = new FileReader();
      reader?.addEventListener("load", () => setSrc2(reader.result));
      reader?.readAsDataURL(e.target.files[0]);
    }
  };
  return (
    <>
      <div className="flex justify-between border-b-2 border-zinc-700">
        <h1 className="text-xl font-bold mb-4">Episode {episodeChoson}</h1>
        <X onClick={handleReset} size={25} className="cursor-pointer" />
      </div>
      {getEpisodeLoader ? (
        <LoaderCircle size={40} className="animate-spin m-auto mt-10" />
      ) : (
        <div className="flex flex-col-reverse md:flex-row gap-5 mt-5">
          <div className="flex flex-col w-full">
            <h1 className="text-xl font-bold mb-4">Video Details</h1>
            <form onSubmit={handleSubmit}>
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-300 ml-1 mb-1"
              >
                Title
              </label>
              <input
                id="title"
                type="text"
                value={title}
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                required
                onChange={(e) => dispatch(setTitle(e.target.value))}
              />
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-300 ml-1 mb-1"
              >
                Description
              </label>
              <textarea
                id="description"
                value={description}
                rows="3"
                onChange={(e) => dispatch(setDescription(e.target.value))}
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                required
              />

              <>
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-300 ml-1 mb-1"
                >
                  Prompt
                </label>
                <textarea
                  id="prompt"
                  value={prompt}
                  rows="2"
                  placeholder="Please provide prompts & tools that you used for creating this. Otherwise, royalties might not apply"
                  onChange={(e) => dispatch(setPrompt(e.target.value))}
                  className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                  required
                />
              </>

              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-300 ml-1 mb-1"
              >
                Seed
              </label>
              <input
                id="seed"
                type="text"
                value={seed}
                placeholder="Please provide seeds. Otherwise, royalties might not apply"
                className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                required
                onChange={(e) => dispatch(seetSeed(e.target.value))}
              />

              {/* <label className="block text-sm font-medium text-gray-300 ml-1 mb-1">
              Season
            </label>
            <input
              id="season"
              type="text"
              value={timeline?.length + 1 || 1}
              className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              disabled
            /> */}
              {/* <div className="flex gap-2">
              <select
                className="block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                onChange={(e) => dispatch(setSeriesSeason(e.target.value))}
                value={seriesSeason}
                required
              >
                <option value="">Select a season</option>
                <option key={1} value={1}>
                  1
                </option>
                <option key={2} value={2}>
                  2
                </option>
                <option key={3} value={3}>
                  3
                </option>
              </select>
            </div> */}
              {/* <label className="block text-sm font-medium text-gray-300 ml-1 mb-1 mt-2">
              Episode
            </label>
            <input
              id="episode"
              type="text"
              value={episodeChoson}
              className="mb-2 block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
              disabled
            /> */}
              {/* <div className="flex gap-2">
              <select
                className="block w-full border-2 border-zinc-600 rounded-md p-3 bg-zinc-800 focus:border-blue-500 outline-none"
                onChange={(e) => dispatch(setSeriesEpisode(e.target.value))}
                value={seriesEpisode}
                required
              >
                <option value="">Select episode</option>

                <option key={1} value={1}>
                  1
                </option>
                <option key={2} value={2}>
                  2
                </option>
                <option key={3} value={3}>
                  3
                </option>
              </select>
            </div> */}

              <button
                type="submit"
                className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600  mt-4"
              >
                {loading ? (
                  <div className="flex items-center justify-center">
                    <LoaderCircle className="animate-spin mr-2" size={22} />
                  </div>
                ) : editEpisodeId == null ? (
                  "Save"
                ) : (
                  "Update"
                )}
              </button>
            </form>
          </div>
          <div className="flex flex-col md:w-1/3">
            {videoUrl ? (
              <>
                <h1 className="text-xl font-bold mb-4">Video Preview</h1>
                <video className="w-full mb-4" controls src={videoUrl} />
                Show Link
                <div className="flex items-center gap-2">
                  <div className="text-sm text-gray-400 flex-1 line-clamp-1">
                    {videoUrl}
                  </div>
                  <Copy
                    className="cursor-pointer"
                    size={18}
                    onClick={copyToClipboard}
                  />
                </div>
                {lodingTnail ? (
                  <div className="flex items-center justify-center w-full mt-5 h-20 border-2 border-dashed border-zinc-600 rounded-lg">
                    <LoaderCircle className="animate-spin mr-2" size={25} />
                    <span>Generating Thumbnail</span>
                  </div>
                ) : (
                  <div>
                    {frames.length > 0 && (
                      <h1 className="text-base mb-1 mt-4">Select from video</h1>
                    )}
                    <div className="flex flex-wrap gap-2">
                      {frames.map((frame, index) => (
                        <img
                          key={index}
                          src={frame}
                          alt={`Frame ${index + 1}`}
                          style={{
                            width: "150px",
                            height: "auto",
                            border: "1px solid #ccc",
                          }}
                          onClick={() => setSrc(frame)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                <div className="flex justify-between items-center mt-4 mb-2">
                  <h1 className="text-xl font-bold ">Thumbnail</h1>
                  {poster && (
                    <Trash
                      className="cursor-pointer"
                      size={20}
                      onClick={() => dispatch(setPoster(null))}
                    />
                  )}
                </div>
                <input
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) => handleFileChange(e, 0)}
                  id="file-image"
                />
                {imageLoading ? (
                  <div className="m-auto mt-5">
                    <LoaderCircle className="animate-spin m-auto" size={22} />
                    <div className="text-sm text-gray-400 text-center">
                      Uplaod Image...
                    </div>
                  </div>
                ) : poster ? (
                  <img
                    className="w-full aspect-video cursor-pointer"
                    src={poster}
                    alt="Thumbnail"
                    onClick={() =>
                      document.getElementById("file-image").click()
                    }
                  />
                ) : (
                  <>
                    {src ? (
                      <div>
                        <ReactCrop
                          crop={crop}
                          onChange={(newCrop) => setCrop(newCrop)}
                          onComplete={(c) => setCompletedCrop(c)}
                          aspect={16 / 9}
                        >
                          <img
                            ref={imgRef}
                            alt="Crop me"
                            src={src}
                            onLoad={onImageLoad}
                            className="max-w-full"
                          />
                        </ReactCrop>
                        <canvas ref={canvasRef} style={{ display: "none" }} />
                        <div className="flex gap-2">
                          <button
                            onClick={() => setSrc(null)}
                            className="bg-red-500 text-white px-4 py-2 mt-4 rounded w-full"
                          >
                            Cancel
                          </button>
                          <button
                            onClick={() => handleCrop(0)}
                            className="bg-blue-500 text-white px-4 py-2 mt-4 rounded w-full"
                          >
                            Crop & Upload
                          </button>
                        </div>
                      </div>
                    ) : (
                      <label htmlFor="file-image" className="cursor-pointer">
                        <div className="flex items-center text-center justify-center w-full h-40 border-2 border-dashed border-zinc-600 rounded-lg hover:bg-zinc-800">
                          Upload Thumbnail
                        </div>
                      </label>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="flex flex-col h-[100%]">
                <h1 className="text-xl font-bold mb-4">Upload Show</h1>

                {uploading ? (
                  <>
                    <div className="text-gray-500 text-sm flex gap-2 mt-10 mb-2 w-full">
                      uploading file{" "}
                      <LoaderCircle className="animate-spin" size={14} />
                    </div>
                    <div className="flex items-center gap-2 w-full">
                      <div className="h-2 w-full bg-gray-100 rounded-full overflow-hidden ">
                        <div
                          style={{ width: progress + "%" }}
                          className="h-full w-full rounded-full bg-blue-400"
                        />
                      </div>
                      <span className="font-bold">{progress}%</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="border-2 border-dashed border-gray-300 rounded-lg p-4 text-center cursor-pointer h-40 flex items-center justify-center w-full"
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onClick={() =>
                        document.getElementById("file-input").click()
                      }
                    >
                      <p className="text-gray-500">
                        Drag & drop your Show here or click to select
                      </p>
                    </div>
                    <input
                      type="file"
                      accept="video/*"
                      onChange={handleFileChangeNew}
                      style={{ display: "none" }}
                      id="file-input"
                    />
                    <label
                      htmlFor="file-input"
                      className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 cursor-pointer block text-center mt-4"
                    >
                      Select Show
                    </label>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
