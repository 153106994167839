import React from "react";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";

function Comments() {
  const navigate = useNavigate();
  return (
    <div className="mt-5">
      <div className="flex items-center px-4 lg:px-6">
        <span
          className="bg-zinc-800 p-2 rounded-full cursor-pointer hover:bg-zinc-700"
          onClick={() => navigate(-1)}
        >
          <ChevronLeft size={22} />
        </span>
        <h1 className="text-3xl font-bold text-center m-auto">Comments</h1>
      </div>
    </div>
  );
}

export default Comments;
