import { useEffect } from "react";
import mixpanel from "mixpanel-browser";
import FbPixel from "../utils/fbPixel";
import { Helmet } from "react-helmet";
import { ChevronLeft } from "lucide-react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css"; // Don't forget to import the CSS for AOS

export default function ZingrollFilmFest() {
  useEffect(() => {
    mixpanel.track("Navigate to Zingroll Film Festival");
    FbPixel("Navigate to Film Fest Page");
    AOS.init({ duration: 1000 }); // Initialize AOS with custom duration if needed
  }, []);

  return (
    <>
      <Header />
      <div className="text-white flex flex-col gap-6 max-w-7xl mx-auto p-6 md:p-10">
        <Helmet>
          <title>Zingroll AI Film Fest 2025</title>
          <meta
            name="description"
            content="Join the Zingroll AI Film Fest 2025 at IIT Bombay and compete for cash prizes, contracts, and more."
          />
        </Helmet>

        {/* Back Button */}
        <div>
          <span className="p-2 bg-zinc-800 rounded-full inline-block shadow-md hover:bg-zinc-700 transition">
            <ChevronLeft
              onClick={() => window.history.back()}
              className="cursor-pointer w-6 h-6"
            />
          </span>
        </div>

        {/* Header */}
        <h1 className="text-4xl font-extrabold text-center" data-aos="fade-up">
          🎬 Zingroll AI Film Fest @ IIT Bombay 2025
        </h1>
        <p className="text-gray-400 text-sm text-center" data-aos="fade-up">
          Posted on March 17th, 2025
        </p>

        <div
          className="text-base lg:text-lg leading-relaxed bg-zinc-800 p-4 rounded-lg shadow-lg"
          data-aos="fade-up"
        >
          We’re proud to announce Zingroll Film Fest 2025 at IIT Bombay. This is
          India's first festival dedicated to AI-generated shows created by
          indie artists. Compete for cash prizes (
          <strong>₹2L+ prize pool</strong>), Bollywood & Hollywood contracts,
          and collaborations with top creators and actors.
        </div>

        {/* About Section */}
        <section className="space-y-4" data-aos="fade-up">
          <h2 className="text-2xl font-semibold">About Zingroll</h2>
          <p className="text-base lg:text-lg leading-relaxed">
            We’re the world's largest platform for hosting quality AI shows and
            movies made by indie artists. We’re changing the way media is
            produced and consumed, as AI bypasses the need for big budgets and
            gatekeepers. Whether you’re a CS student or a filmmaking enthusiast,
            anyone can create for Zingroll and gain world-class recognition as a
            creator.
          </p>
          <p className="text-base lg:text-lg">
            If you’re interested, feel free to schedule a 15 min call{" "}
            <a
              href="https://calendly.com/harshit-zingroll/30min"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-400 "
            >
              here{" "}
            </a>
            to chat with our product team. team.
          </p>
        </section>

        {/* Key Dates */}
        <section className="space-y-4" data-aos="fade-up">
          <h2 className="text-2xl font-semibold">Key Dates</h2>
          <ul className="list-disc pl-6  space-y-2">
            <li>
              <strong>March 28th:</strong> Submissions open
            </li>
            <li>
              <strong>April TBD:</strong> In-person workshop to help you create
              AI shows
            </li>
            <li>
              <strong>April TBD +8:</strong> Closing Ceremony with screenings
              and awards
            </li>
          </ul>
        </section>

        {/* Production Tips */}
        <section className="space-y-4" data-aos="fade-up">
          <h2 className="text-2xl font-semibold">
            How to make AI Shows / Trailers
          </h2>
          <p className="text-base lg:text-lg leading-relaxed">
            It doesn’t take more than a couple of minutes to a few hours of
            dedicated creative work to make a show/video using AI tools. Please
            check our{" "}
            <a href="/zingroll-production-tips" className="text-blue-400">
              Production process and best practices
            </a>
            .
          </p>
        </section>

        {/* Tools */}
        <section className="space-y-4" data-aos="fade-up">
          <h2 className="text-2xl font-semibold">Tools Used</h2>
          <p className="text-base lg:text-lg">
            <a href="/zingroll-production-tips" className="text-blue-400 ">
              Here’s a list of tools{" "}
            </a>
            we use. Contact @----------------- at IIT B or email us for pro
            subscriptions for the event.
          </p>
        </section>

        {/* Rules */}
        <section className="space-y-4" data-aos="fade-up">
          <h2 className="text-2xl font-semibold">Rules</h2>
          <ul className="list-disc pl-6 text-base lg:text-lg space-y-2">
            <li>
              <p>
                Check{" "}
                <a href="https://zingroll.com" className="text-blue-400 ">
                  zingroll.com
                </a>{" "}
                for the quality expected for your trailers and shows. You can
                make anime, hyper-realistic, or 3D shows (it is generally easier
                to make anime and 3D shows). We have cash prizes and awards for
                each category.
              </p>
            </li>
            <li>
              Teams must submit a 30-second trailer and 5 episodes of 1-1.5 mins
              each.
            </li>
            <li>
              It is recommended that each episode has cliffhanger content
              leaving the viewer excited to watch the next episode. This will be
              the primary criteria for judgement, besides quality of video and
              audio production.
            </li>
            <li>
              Make sure that your characters remain consistent. Use{" "}
              <a
                href="https://leonardo.ai/"
                target="_blank"
                className="text-blue-400 "
              >
                Leonardo AI
              </a>{" "}
              or any other method / tool that helps you generate consistent
              starting shots for the same character when needed.
            </li>
            <li>No limitation on which tools to use.</li>
            <li>You can mix real recordings, voices, and AI outputs.</li>
            <li>Credit everyone involved in your submission.</li>
            <li>Most importantly, have fun!</li>
          </ul>
        </section>

        {/* More Info */}
        <section className="space-y-4" data-aos="fade-up">
          <h2 className="text-2xl font-semibold">Need More Info?</h2>
          <p className="text-base lg:text-lg">
            Reach out to{" "}
            <a href="mailto:harshit@zingroll.com" className="text-blue-400 ">
              harshit@zingroll.com
            </a>{" "}
            for event logistics. Check our{" "}
            <a href="/faq" className="text-blue-400 ">
              FAQ page
            </a>{" "}
            to learn more. Best of luck!
          </p>
        </section>
      </div>
      <Footer />
    </>
  );
}
