import { useDispatch, useSelector } from "react-redux";
import Loader from "../UI/Loader";
import { Fragment, useState, useRef, useEffect } from "react";
import { Edit, Pause, Pen, Play, X, Plus, Check } from "lucide-react";
import { useNavigate } from "react-router-dom";
import EditEpisode from "./editEpisode";
import AddEpisode from "./addEpisode";
import { setEditEpisode } from "../../redux/slice/templateGroups/episodeEdit";
import SelectTimeLine from "./editTimeLine";
import Trailer from "../../pages/Trailer";
import { getSavedData } from "../../redux/slice/Upload2";
import AddNewSeason2 from "../../pages/AddNewSeason2";

export default function EpisodeList({ modalRef, series }) {
  const { data, loading, error } = useSelector((state) => state.episodes);
  const { user } = useSelector((state) => state.auth);
  const { series: S } = useSelector((state) => state.upload);
  const [myTrailer, setMyTrailer] = useState([]);
  const [showTrailer, setShowtrailer] = useState(false);
  const editEpisode = useRef();
  const editTimeLile = useRef();
  const addEpisode = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [season, setSeason] = useState(1);
  const [seasonEpisodes, setSeasonEpisodes] = useState([]);
  const [selectedSeasonNumber, setselectedSeasonNumber] = useState(null);
  const diffSeason = [
    ...new Set(
      data?.episodes
        .filter((item) => item.userId == user?.id)
        .map((item) => item.seriesSeason)
        .filter((season) => season !== null)
    ),
  ].sort((a, b) => a - b);
  useEffect(() => {
    const isTraier = S.filter(
      (e) => e.trailer && e.userId == user.id && e.id == data?.templateGroupID
    );
    setMyTrailer(isTraier);

    dispatch(getSavedData(data?.templateGroupID));
    const res = JSON.parse(localStorage.getItem(data?.templateGroupID));
    if (res != null) {
      // console.log(res);
      setselectedSeasonNumber(res.selectedSeasonNumber);
    }
  }, [data?.templateGroupID]);

  useEffect(() => {
    setSeason(diffSeason[0]);

    return () => {
      setselectedSeasonNumber(null);
      setShowtrailer(false);
      setMyTrailer(null);
    };
  }, [data]);

  useEffect(() => {
    if (diffSeason.length != 1) {
      setSeasonEpisodes(
        data?.episodes.filter(
          (item) => item?.seriesSeason == season && item?.userId == user?.id
        )
      );
    } else {
      setSeasonEpisodes(data?.episodes);
    }
  }, [season, data]);

  function Content() {
    if (loading) {
      return <Loader message="Fetching Episodes" />;
    }
    if (error) {
      return <div className="text-red-500">{error}</div>;
    }
    return (
      <Fragment>
        <X
          onClick={() => modalRef.current.close()}
          size={25}
          className="cursor-pointer absolute top-3 right-3"
        />
        {myTrailer.length != 0 && (
          <div className="flex justify-center">
            <div className="flex gap-2 bg-zinc-800 rounded-full overflow-hidden px-4 cursor-pointer">
              <div
                className={
                  !showTrailer
                    ? "text-blue-600 font-semibold border-b-2 border-blue-600 pb-2 px-4 py-2"
                    : "text-gray-400 hover:text-blue-600 pb-2 px-4 py-2 "
                }
                onClick={() => setShowtrailer(false)}
              >
                Shows
              </div>
              <div
                className={
                  showTrailer
                    ? "text-blue-600 font-semibold border-b-2 border-blue-600 pb-2 px-4 py-2"
                    : "text-gray-400 hover:text-blue-600 pb-2 px-4 py-2 "
                }
                onClick={() => setShowtrailer(true)}
              >
                Trailer
              </div>
            </div>
          </div>
        )}
        <div className="flex items-center gap-2 mt-2 mb-2">
          <h1 className="text-xl font-bold ">{data?.name}</h1>
          {data?.userId == user?.id && !showTrailer && (
            <button
              onClick={(e) => {
                navigate("/publish");
              }}
              className="btn-primary ml-auto flex items-center gap-1 bg-blue-500 text-white px-2 py-1 rounded-md"
            >
              Add Episode
            </button>
          )}
        </div>
        {!showTrailer ? (
          <>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <h2 className="text-xl font-semibold text-white">
                  Season {season}
                </h2>
                <span className="bg-blue-500 text-xs text-white p-1 px-3 rounded-full">
                  {seasonEpisodes?.length} Episodes
                </span>
              </div>
              {/* {showContinue && (
                <div
                  className="bg-green-700 flex flex-col gap-4 shadow-md justify-between rounded-lg md:items-center md:flex-row p-2 px-3 cursor-pointer"
                  onClick={() => {
                    navigate(`/add/${data.templateGroupID}`, {
                      state: { data: data },
                    });
                  }}
                >
                  continue your form
                </div>
              )} */}
              <div className="flex items-center gap-3">
                {/* <button
              onClick={() => {
                addEpisode.current.showModal();
              }}
              className="btn-primary text-white px-2 py-1 rounded-md bg-blue-600"
            >
              <Plus size={23} />
            </button> */}
                {diffSeason.length != 1 && (
                  <select
                    className="px-4 py-2 rounded-lg bg-zinc-800 text-white"
                    value={season}
                    onChange={(e) => setSeason(e.target.value)}
                  >
                    {diffSeason.map((s, index) => (
                      <option key={index} value={s}>
                        Season {s}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            <div className="overflow-y-auto flex-1">
              {seasonEpisodes?.length == 0 ? (
                <div className="flex items-center justify-center h-full">
                  <span className="text-gray-400">No Episodes Found</span>
                </div>
              ) : (
                <div className="bg-zinc-900 flex flex-col gap-4 shadow-md justify-between rounded-lg md:items-center md:flex-row">
                  {season == selectedSeasonNumber ? (
                    <div className="overflow-x-auto w-full">
                      <AddNewSeason2 id={data?.templateGroupID} />
                    </div>
                  ) : (
                    <div className="overflow-x-auto w-full">
                      <div className="flex flex-col gap-2">
                        <p className="text-lg font-medium">Select Episodes:</p>
                        {seasonEpisodes?.map((item, index) => (
                          <div
                            key={index + 1}
                            className={`flex items-center gap-2 border-2 mb-2 border-gray-300 rounded-lg p-4 text-center cursor-pointer hover:cursor-pointer hover:border-blue-500 justify-between ${"bg-green-700"}`}
                            onClick={() => {
                              dispatch(setEditEpisode(item));
                              editEpisode.current.showModal();
                            }}
                          >
                            <label
                              htmlFor={`episode-${index + 1}`}
                              className="text-base"
                            >
                              Episode {item.seriesEpisode || item.episodNumber}{" "}
                              - {item.name}
                            </label>
                            <Check size={25} />
                          </div>
                        ))}
                      </div>
                      {seasonEpisodes?.length > 0 &&
                        seasonEpisodes[0]?.timeLine &&
                        seasonEpisodes[0]?.timeLine.length > 0 && (
                          <div className="mt-8 mb-20">
                            <div className="flex justify-between items-center mb-4">
                              <h2 className="text-lg font-semibold ">
                                Selected Seasons Timeline
                              </h2>
                              <Pen
                                size={20}
                                className="cursor-pointer"
                                onClick={() => {
                                  // dispatch(setEditEpisode(item));
                                  editTimeLile.current.showModal();
                                }}
                              />
                            </div>
                            <div className="space-y-2">
                              {/* {console.log(
                          "Timeline data being passed:",
                          seasonEpisodes[0]?.timeLine
                        )} */}
                              {renderTimeline(seasonEpisodes[0]?.timeLine)}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="h-full overflow-scroll flex-1">
            <Trailer isTraier={myTrailer} />
          </div>
        )}
      </Fragment>
    );
  }

  const renderTimeline = (timelineData) => {
    // console.log("Inside renderTimeline:", {
    //   timelineData,
    //   isArray: Array.isArray(timelineData),
    //   type: typeof timelineData,
    // });

    if (!timelineData) {
      // console.log("Timeline data is falsy");
      return null;
    }

    if (!Array.isArray(timelineData)) {
      // console.log("Timeline data is not an array, attempting to parse");
      try {
        // If it's a string, try to parse it
        timelineData =
          typeof timelineData === "string"
            ? JSON.parse(timelineData)
            : timelineData;
      } catch (e) {
        console.error("Failed to parse timeline data:", e);
        return null;
      }
    }

    if (!Array.isArray(timelineData)) {
      console.log("Timeline data is still not an array after parsing");
      return null;
    }

    return timelineData.map((item, index) => (
      <div key={index} className="border border-zinc-700 rounded-lg p-4">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold">
            Season {item?.season} by - {item?.userId}
          </h3>
          <span className="text-sm text-gray-400">
            {item?.episodes?.length || 0} Episodes Selected
          </span>
        </div>
        <div className="pl-4 border-l-2 border-blue-500">
          {item?.episodes?.map((episodeId, epIndex) => (
            <div key={episodeId} className="flex items-center gap-2 py-2">
              <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
              <span className="text-gray-300">Episode {epIndex + 1}</span>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  return (
    <dialog ref={modalRef} className="modal text-white">
      <div
        className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center p-4"
        onClick={() => modalRef.current.close()}
      >
        <div
          className="bg-zinc-900 h-auto min-h-[600px] max-h-[90%] p-8 px-3 md:px-6 rounded-lg shadow-lg justify-center max-w-5xl w-full flex-col flex gap-4 relative overflow-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <Content />
        </div>
      </div>
      <EditEpisode modalRef={editEpisode} modalRef2={modalRef} />
      <SelectTimeLine modalRef={editTimeLile} season={season} />
      <AddEpisode
        modalRef={addEpisode}
        modalRef2={modalRef}
        season={season}
        episode={seasonEpisodes?.length + 1}
      />
    </dialog>
  );

  function EpisodeListItem(props) {
    const [isPlaying, setIsPlaying] = useState();
    const {
      description,
      name,
      id,
      status,
      videoUrl,
      episodNumber,
      seriesEpisode,
      imageUrl,
    } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const togglePlayPause = () => {
      if (!videoRef.current) return;
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    };
    const videoRef = useRef(null);
    return (
      <tr>
        <td>
          <div className="flex items-center gap-2">
            <div className="h-[150px] min-w-[250px] w-[250px] flex-shrink-0">
              <video
                ref={videoRef}
                src={videoUrl}
                controls
                poster={imageUrl || "/default.png"}
                className="size-full rounded-md object-cover"
              />
            </div>
            <div className="flex flex-col grow w-[200px]">
              <div className="grid  mb-auto">
                <span className="h-[26px]">
                  <b>Name: </b>
                  {name}
                </span>
                <span className="min-h-[26px]">
                  <b>Description: </b>
                  {description || <i>emtpy</i>}
                </span>
              </div>
            </div>
          </div>
        </td>
        <td className="text-center">{seriesEpisode || episodNumber}</td>
        <td className="text-center">
          {new Date(props?.createdDate).toLocaleDateString()}
        </td>
        <td className="text-center">
          <button
            onClick={() => {
              dispatch(setEditEpisode(props));
              editEpisode.current.showModal();
            }}
            className="btn-primary text-white px-2 py-1 rounded-md hover:bg-blue-600"
          >
            <Pen size={20} />
          </button>
        </td>
      </tr>
    );
  }
}
