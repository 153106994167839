import { LoaderCircle, X, Check, Pen } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  setNoOfEpisode,
  setSteps,
  setNoOfEpisodesUploaded,
  setEpisodeChoson,
  setSeriesEpisode,
  saveForLater,
  getSavedEpisode,
} from "../../redux/slice/Upload";

function UploadVideo({ data }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { noOfEpisodesEnter, noOfEpisodesUploaded, timeline } = useSelector(
    (state) => state.upload
  );
  const renderTimeline = (timelineData) => {
    return timelineData.map((item, index) => (
      <div key={index} className="border border-zinc-700 rounded-lg p-4">
        <div className="flex items-center justify-between mb-2">
          <h3 className="text-lg font-semibold">
            Season {item.season} by - {item.userId}
          </h3>
          <span className="text-sm text-gray-400">
            {item.episodes.length} Episodes Selected
          </span>
        </div>
        <div className="pl-4 border-l-2 border-blue-500">
          {item.episodes.map((episodeId, epIndex) => (
            <div key={episodeId} className="flex items-center gap-2 py-2">
              <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
              <span className="text-gray-300">Episode {epIndex + 1}</span>
            </div>
          ))}
        </div>
      </div>
    ));
  };
  const submitForReview = async () => {
    if (noOfEpisodesUploaded?.length == noOfEpisodesEnter) {
      localStorage.removeItem(id);
      toast.success("Show is send for review");
      navigate(-1);
    }
  };
  const handleEpisodeClick = (episodeNumber) => {
    dispatch(setEpisodeChoson(episodeNumber));
    dispatch(setSeriesEpisode(episodeNumber));
    // If episode was already uploaded, get the saved data
    if (noOfEpisodesUploaded?.includes(episodeNumber)) {
      dispatch(getSavedEpisode(episodeNumber));
    }
    dispatch(setSteps(3));
  };
  return (
    <>
      <div className="flex justify-between border-b-2 border-zinc-700">
        <h1 className="text-xl font-bold mb-4">{data?.title}</h1>
        <X
          onClick={() => dispatch(setSteps(1))}
          size={25}
          className="cursor-pointer"
        />
      </div>

      <div className="mt-6 flex flex-col gap-4">
        <div>
          <label
            htmlFor="numEpisodes"
            className="block mb-2 text-base font-medium"
          >
            Number of Episodes:
          </label>
          <input
            type="number"
            id="numEpisodes"
            value={noOfEpisodesEnter}
            onChange={(e) => dispatch(setNoOfEpisode(e.target.value))}
            className="bg-zinc-800 border border-zinc-700 text-white text-base rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            min="1"
            placeholder="Enter number of episodes"
          />
        </div>

        {noOfEpisodesEnter && (
          <div className="flex flex-col gap-2">
            <p className="text-lg font-medium">Select Episodes:</p>
            {[...Array(parseInt(noOfEpisodesEnter))].map((_, index) => (
              <div
                key={index + 1}
                className={`flex items-center gap-2 border-2 mb-2 border-gray-300 rounded-lg p-4 text-center cursor-pointer hover:cursor-pointer hover:border-blue-500 justify-between ${
                  noOfEpisodesUploaded?.includes(index + 1)
                    ? "bg-green-700"
                    : ""
                }`}
                onClick={() => handleEpisodeClick(index + 1)}
              >
                <label htmlFor={`episode-${index + 1}`} className="text-base">
                  Episode {index + 1} - Upload Details
                </label>
                <Check
                  size={25}
                  className={`${
                    noOfEpisodesUploaded?.includes(index + 1)
                      ? "block"
                      : "hidden"
                  }`}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {timeline && timeline.length > 0 && (
        <div className="mt-8 mb-20">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold ">
              Selected Seasons Timeline
            </h2>
            <Pen
              size={20}
              className="cursor-pointer"
              onClick={() => dispatch(setSteps(1))}
            />
          </div>
          <div className="space-y-2">{renderTimeline(timeline)}</div>
        </div>
      )}

      <div className=" max-w-5xl m-auto p-2 fixed bottom-4 left-0 right-0">
        <div className=" mx-auto flex gap-2">
          <button
            onClick={() => {
              dispatch(saveForLater(id));
              toast.success("Your progress has been saved for later");
            }}
            className="w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 text-white font-semibold rounded-lg transition-colors"
          >
            Save for Later
          </button>
          <button
            onClick={submitForReview}
            className={`w-full py-3 px-4 text-white font-semibold rounded-lg transition-colors ${
              noOfEpisodesUploaded?.length == noOfEpisodesEnter
                ? "bg-blue-600"
                : "bg-gray-600"
            }`}
          >
            Submit for Review
          </button>
        </div>
      </div>
    </>
  );
}

export default UploadVideo;
