import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../utils/api";
import axios from "axios";

export const fetchAnalytics = createAsyncThunk(
  "fetchAnalytics34[",
  async (_, { getState }) => {
    try {
      const state = getState();
      const { allOtherGroups } = state.movie;
      const userId = state.auth.user.id;
      const res = await api.post("/videotemplate/get-template-by-categoryID", {
        categoryID: userId,
        limit: 1000,
      });
      return {
        video: res.data.searchData,
        allOtherGroups: allOtherGroups.filter((e) => e.userId == userId),
        allOtherGroups2: allOtherGroups,
      };
    } catch (error) {
      if (axios.isCancel(error)) {
        throw new Error("request was cancelled");
      } else {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: {
    loading: false,
    error: "",
    data: [],
    series: [],
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAnalytics.pending, (state, action) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(fetchAnalytics.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchAnalytics.fulfilled, (state, action) => {
      state.loading = false;
      state.error = "";
      state.data = action.payload.video;
      state.series = action.payload.allOtherGroups;

      const series = JSON.parse(JSON.stringify(state.series));
      // console.log("series", series);
      const other = [
        ...new Set(
          action.payload.video
            .filter((e) => series.every((s) => s.id !== e.templateGroupID))
            .map((e) => e.templateGroupID)
        ),
      ];
      // console.log("other", other);

      const a = action.payload.allOtherGroups2.filter((e) =>
        other.includes(e.id)
      );
      // console.log("aa", a);
      const group = [...a, ...state.series];

      const newGroup = group.map((e) => {
        const totalViews = state.data
          .filter((item) => item.templateGroupID == e.id)
          .reduce((acc, curr) => acc + curr.views, 0);
        const totalLikes = state.data
          .filter((item) => item.templateGroupID == e.id)
          .reduce((acc, curr) => acc + curr.likes, 0);
        const totalDislikes = state.data
          .filter((item) => item.templateGroupID == e.id)
          .reduce((acc, curr) => acc + curr.dislikes, 0);
        const totalComments = state.data
          .filter((item) => item.templateGroupID == e.id)
          .reduce((acc, curr) => acc + curr.comments, 0);
        return {
          ...e,
          views: totalViews,
          dislikes: totalDislikes,
          comments: totalComments,
          likes: totalLikes,
        };
      });

      state.series = newGroup;
    });
  },
});

export default analyticsSlice.reducer;
